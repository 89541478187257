import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { IconButton, Collapse } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Typography, Card, Avatar } from '.'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minHeight: theme.spacing(10),
            boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
            borderRadius: theme.spacing(2),
            '& > .MuiPaper-root': {
                // transition: 'all 0.3s ease-out'
            },
            '& .MuiCollapse-container': {
                marginTop: -(theme.spacing(2)),
            },
            '& .MuiCollapse-wrapperInner': {
                '& .MuiPaper-root': {
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                }
            },
            '&.checked': {

            }
        },
        body: {
            padding: `${ theme.spacing(2) }px ${ theme.spacing(2.85) }px`,
        },
        header: {
            padding: `${ theme.spacing(2) }px ${ theme.spacing(2) }px ${ theme.spacing(2) }px ${ theme.spacing(2.85) }px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > *:not(:last-child)': {
                    marginRight: theme.spacing(2)
                }
            }
        },
        avatar: {
            width: 40,
            height: 40,
            [theme.breakpoints.down("md")]: {
                width: 30,
                height: 30
            },
            [theme.breakpoints.down("xs")]: {
                width: 25,
                height: 25
            }
        },
        number: {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.purple.main
        }
    }),
)

export default function CardCollapse({ number, title, titleSingular, profile, children, defaultChecked = false, lock }) {
    const classes = useStyles()
    const [checked, setChecked] = React.useState(defaultChecked)

    titleSingular = titleSingular || title

    const handleChange = () => {
        setChecked((prev) => {
            return !prev
        })
    }

    return (
        <div className={`${ classes.root } ${ checked && 'checked' }`}>
            <Card shadowOff>
                <div className={classes.header}>
                    <div>
                        {typeof number !== 'undefined' && <Typography variant="h1" className={classes.number} color="primary" shade="light">{number}</Typography>}
                        {typeof profile !== 'undefined' && <Avatar className={classes.avatar} profile={profile} />}
                        <Typography variant="body1" shade="dark">{typeof number !== 'undefined' && parseInt(number) === 1 ? titleSingular : title}</Typography>
                    </div>
                    {!lock && (
                        <IconButton color="primary" onClick={handleChange}>
                            {checked ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </div>
            </Card>
            <Collapse in={checked}>
                <Card shadowOff className={classes.body}>
                    {children}
                </Card>
            </Collapse>
        </div>
    )
}
