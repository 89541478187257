import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from "@material-ui/core/styles"
import { Link } from 'gatsby'
import theme, { palette } from '../../theme'

const Custom = withStyles({
    root: {
        background: props => props.gradient,
        borderRadius: '20em',
        minWidth: theme.spacing(14),
        marginLeft: props => props.align === 'left' ? 0 : 'auto',
        marginRight: props => props.align === 'right' ? 0 : 'auto',
        width: props => props.width,
        boxShadow: 'none',
        '& + .MuiButton-root': {
            marginLeft: theme.spacing(3)
        },
        visibility: props => props.invisible ? 'hidden' : 'visible'
    }
})(Button)


function ButtonComponent({ children, to, type, variant, disabled = false, color = 'default', size = 'medium', align = 'center', inline, onClick, invisible, href, style = {}, className }) {
    const attrs = {}
    const is_external_link = typeof href !== 'undefined'
    const is_link = typeof to !== 'undefined'
    const comp = is_link ? Link : is_external_link ? 'a' : 'button'
    if (is_link) {
        attrs.to = to
    } else if (is_external_link) {
        attrs.href = href
        attrs.target = '_blank'
        attrs.rel = 'noopener noreferrer'
    }
    if (variant) {
        attrs.variant = variant
    }
    let gradient = null
    if (variant === 'contained' && !disabled) {
        switch (color) {
            case 'primary':
                gradient = `linear-gradient(90deg, ${ palette.primary.main } 30%, ${ palette.pink.main } 90%)`
                break
            case 'secondary':
                gradient = `linear-gradient(90deg, ${ palette.secondary.main } 10%, ${ palette.secondary.light } 90%)`
                break
        }

    }
    const width = inline ? 'auto' : '100%'


    const onClickFn = (e) => {
        if (onClick) onClick(e)
    }

    if (onClick) {
        attrs.onClick = onClickFn
    }
    if(className){
        attrs.className = className
    }

    return (
        <Custom disabled={disabled} type={type} component={comp} gradient={gradient} width={width} color={color} size={size} align={align} {...attrs} style={style}>{children}</Custom>
    )
}

export default ButtonComponent
