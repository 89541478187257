import React from 'react'
import { Typography, Card, Avatar } from '.'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        }
    },
    header: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(2)
        }
    },
    avatar: {
        width: 30,
        height: 30,
        [theme.breakpoints.down("xs")]: {
            width: 22,
            height: 22
        }
    },
}))

function CardTurn({ profile, description, shade = 'light', children }) {
    const classes = useStyles()
    const bg = shade === 'dark' ? 'purple' : 'purple-light'
    const fg = shade === 'dark' ? 'light' : 'purple'
    const fgShade = shade === 'dark' ? 'light' : 'main'
    const fgDesc = shade === 'dark' ? 'light' : 'gray'
    const fgDescShade = shade === 'dark' ? 'light' : 'dark'
    const label = profile.name && `${profile.name}`.length > 0 ? `${ profile.name }'s turn` : `${ profile.role }'s turn`
    return (
        <Card bg={bg} className={classes.root} shadowOff>
            <div className={classes.header}>
                <Avatar className={classes.avatar} profile={profile} />
                <Typography variant="caption" color={fg} shade={fgShade} uppercase><strong>{label}</strong></Typography>
            </div>
            <div className={classes.body}>
                <Typography variant="body1" align="left" color={fgDesc} shade={fgDescShade}>{description}</Typography>
            </div>
        </Card>
    )
}

export default CardTurn
