import React from 'react'
import { Section, Typography } from '.'
import { palette_scale } from '../../theme'
import { makeStyles } from '@material-ui/core'
import slugify from '../../utils/slugify'

const useStyles = makeStyles((theme) => ({
    answer: {
        marginBottom: theme.spacing(1)
    }
}))


function ReportQuiz({ title, questions }) {
    const classes = useStyles()
    return (
        <Section title={title}>
            {questions.map((q) => (
                <div key={slugify(q.title)}>
                    <Typography variant="body2" align="left" shade="dark">{q.title}</Typography>
                    <Typography variant="body2" align="left" color="primary" className={classes.answer}>{q.answer}</Typography>
                    {q.notes && q.notes.map((note) => <Typography key={slugify(note)} variant="caption" align="left" shade="light">{note}</Typography>)}
                </div>
            ))}
        </Section>
    )
}

export default ReportQuiz
