import React from 'react'
import Paper from '@material-ui/core/Paper'
import { withStyles } from "@material-ui/core/styles"
import theme, { palette } from '../../theme'
import hexToRgbA from '../../utils/hexToRgbA'

const defaultColors = ['purple', 'purple-light', 'bg-purple-lighter', 'glass'];

const Custom = withStyles({
    root: {
        borderRadius: theme.spacing(2),
        background: props => props.gradient,
        [theme.breakpoints.down("xs")]: {
            borderRadius: theme.spacing(1),
        },
        '&.overflow-hidden': {
            overflow: 'hidden',
        },
        '&.bg-purple': {
            backgroundColor: palette.purple.light
        },
        '&.bg-purple-light': {
            backgroundColor: palette.purple.lighter
        },
        '&.bg-purple-lighter': {
            backgroundColor: palette.purple.lightest
        },

        '&.bg-glass': {
            backgroundColor: hexToRgbA(theme.palette.purple.lightest, 0.35),
            boxShadow: '0px 0px 10px rgb(0 0 0 / 10%), 0px 0px 20px rgb(255 255 255 / 80%) inset',
            backdropFilter: 'blur(4px)'
        },
        '&.no-shadow': {
            boxShadow: 'none'
        }
    },
    elevation1: {
        boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)'
    }
})(Paper)

function Card({ className = '', bg = 'white', gradient = 'white', children, shadowOff, style, hideOverflow = false }) {
    style = style || {}
    if(!defaultColors.includes(bg)){
        style.backgroundColor = bg
    }
    return (
        <Custom className={`${ className } bg-${ bg } ${ shadowOff && 'no-shadow' } overflow-${hideOverflow ? 'hidden' : 'visible'}`} gradient={gradient} style={style}>
            {children}
        </Custom>
    )
}

export default Card
