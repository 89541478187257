
function genRange(start, end) {
    let range = [];
    for (let i = start; i <= end; i++) {
        range.push(i);
    }
    return range;
}


function getPetLevels(){
    const pet_score_system = [
        {level: 1, pet_id: 'charlie', bones_required: 7},
        {level: 2, pet_id: 'charlie', bones_required: 17},
        {level: 3, pet_id: 'charlie', bones_required: 29},
        {level: 4, pet_id: 'charlie', bones_required: 45},
        {level: 5, pet_id: 'charlie', bones_required: 46},
        // New Pet N1
        {level: 6, pet_id: 'bella', bones_required: 51},
        {level: 7, pet_id: 'bella', bones_required: 56},
        {level: 8, pet_id: 'bella', bones_required: 61},
        {level: 9, pet_id: 'bella', bones_required: 66},
        // New Pet N2
        {level: 10, pet_id: 'coco', bones_required: 71},
        {level: 11, pet_id: 'coco', bones_required: 76},
        {level: 12, pet_id: 'coco', bones_required: 81},
        {level: 13, pet_id: 'coco', bones_required: 86},
        // New Pet N3
        {level: 14, pet_id: 'max', bones_required: 91},
        {level: 15, pet_id: 'max', bones_required: 96},
        {level: 16, pet_id: 'max', bones_required: 101},
        {level: 17, pet_id: 'max', bones_required: 106},
        // New Pet N4
        {level: 18, pet_id: 'lucky', bones_required: 111},
        {level: 19, pet_id: 'lucky', bones_required: 116},
        {level: 20, pet_id: 'lucky', bones_required: 121},
        {level: 21, pet_id: 'lucky', bones_required: 126},
        // New Pet N5
        {level: 22, pet_id: 'teddy', bones_required: 131},
        {level: 23, pet_id: 'teddy', bones_required: 136},
        {level: 24, pet_id: 'teddy', bones_required: 141},
        {level: 25, pet_id: 'teddy', bones_required: 146},
        // New Pet N6
        {level: 26, pet_id: 'cooper', bones_required: 151},
        {level: 27, pet_id: 'cooper', bones_required: 156},
        {level: 28, pet_id: 'cooper', bones_required: 161},
        {level: 29, pet_id: 'cooper', bones_required: 166},
        // New Pet N6
        {level: 30, pet_id: 'stella', bones_required: 171},
        {level: 31, pet_id: 'stella', bones_required: 176},
        {level: 32, pet_id: 'stella', bones_required: 181},
        {level: 33, pet_id: 'stella', bones_required: 186},
    ]
    return pet_score_system.map((lvl, i) => {
        const prev_index = i - 1
        const prev_bones_required = prev_index >= 0 ? pet_score_system[prev_index].bones_required : -1
        return {
            ...lvl,
            index: i,
            range: genRange(prev_bones_required + 1, lvl.bones_required)
        }
    })
}

export default getPetLevels