import React from 'react'
import { Typography, Card, Avatar } from '.'
import { IconButton, makeStyles } from '@material-ui/core'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'gatsby'
import theme from '../../theme'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& > div.profile': {
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
            }
        }
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            marginRight: theme.spacing(2),
        },
        backgroundColor: theme.palette.background.default,
        '&.silhouette > img': {
            '-webkit-filter': 'grayscale(100%) brightness(0)',
            filter: 'grayscale(100%) brightness(0)',
            opacity: '0.6'
        }
    },
    current: {
        borderColor: theme.palette.primary.main,
         borderStyle: 'solid',
         borderRadius: '50%',
    },
    role: {
        textTransform: 'capitalize'
    }
}))

function CardPerson({ profile, edit, current = false, silhouette = false }) {
    const classes = useStyles()
    return (
        <Card bg="purple-lighter" className={classes.root} shadowOff>
            <div>
                <div className="profile">
                    <Avatar className={`${classes.avatar} ${current ? classes.current : ''} ${silhouette ? 'silhouette' : ''}`} profile={profile} />
                    <div>
                        <Typography variant="caption" className={classes.role}>{`${ profile.role != 'petType' ?  profile.role : 'Pet'}`}</Typography>
                        {profile.name && profile.name.length > 0 ? <Typography variant="body2" color="purple">{`${ profile.name }`}</Typography> : <Typography variant="body2" color={theme.palette.gray.lighter}><em>No name</em></Typography>}
                        
                    </div>
                </div>
                {edit && (
                    <IconButton color="primary" component={Link} to={`/account/${ profile.role != 'petType' ? profile.role: `petType?type=${profile.petType}` }`}>
                        <EditIcon />
                    </IconButton>
                )}
            </div>
        </Card>
    )
}

export default CardPerson
