function getPetLevelStatus(levels, petBones, max_level){
    const status = {}
    const found_level = levels.find(lvl => lvl.range.includes(petBones))
    const prev_level_index = found_level && found_level.index - 1 >= 0 ? found_level.index - 1 : -1
    const prev_level = prev_level_index >= 0 ? levels[prev_level_index] : null
    status.level = found_level ? found_level.level : petBones <= 0 ? 1 : levels[levels.length - 1].level
    let found_steps = 0
    let found_progress = 0
    found_steps = prev_level && found_level ?  found_level.bones_required - prev_level.bones_required : levels[0].bones_required + 1
    found_progress = prev_level && found_level ? petBones - (prev_level.bones_required + 1) : petBones
    if(status.level >= max_level){
        found_progress = found_steps
    }
    status.progress = found_progress
    status.steps = found_steps
    status.petType = found_level.pet_id
    status.bonesReqToNewPet = getBonesRequiredToNewPet(found_level, levels, petBones)
    
    return status
}

function getBonesRequiredToNewPet(level, levels, petBones) {
    const { bones_required, pet_id } = level
    levels = levels.filter(lvl => lvl.pet_id === pet_id)
    let last_level = levels[levels.length - 1]
    
    return last_level.bones_required - petBones + 1;
}

export default getPetLevelStatus