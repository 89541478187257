import getStreakStatus from "./getStreakStatus"

function computeBones(user, userData, weeks){
  let count = 0
  if(user?.id){
    // console.log('werarable?', user.attributes.field_wearable_on);
    if(user.attributes.field_wearable_on === true){
      // console.error('adding 2');
      count += 2
    }
  }
  if(userData){
    const keys = Object.keys(userData)
    const has_goal = keys.find(uuid => {
      const itm = userData[uuid]
      return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type === 'node--goal'
    })
    // console.log('has_goal', has_goal);
    if(has_goal){
      // console.error('adding 1');
      count += 1
    }
  
    const has_cact = keys.filter(uuid => {
      const itm = userData[uuid]
      return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type === 'node--cact_response'
    })
    // console.log('has_cact', has_cact);
    if(has_cact.length > 0){
      // console.error('adding', has_cact.length * 2);
      count += has_cact.length * 2
    }
    const has_spirometry = keys.filter(uuid => {
      const itm = userData[uuid]
      return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type === 'node--spirometer_result'
    })
    // console.log('has_spirometry', has_spirometry);
    if(has_spirometry.length > 0){
      // console.error('adding', has_spirometry.length);
      count += has_spirometry.length
    }
    const all_goals_checked_in = keys.filter(uuid => {
      const itm = userData[uuid]
      return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type.includes('goal') && typeof itm.attributes.field_check_in_value !== 'undefined' && itm.attributes.field_check_in_value !== null
    })
    // console.log('all_goals_checked_in', all_goals_checked_in);
    if(all_goals_checked_in.length > 0){
      // console.error('adding', all_goals_checked_in.length * 2);
      count += all_goals_checked_in.length * 2
    }

    let { petBones: streakPetBones } = getStreakStatus(userData)
    count += streakPetBones
  }
  if(weeks && weeks.length > 0){
    const done_weeks = weeks.filter((w) => w.todo_done)
    // console.log('done_weeks', done_weeks);
    // console.error('adding', done_weeks.length);
    count += done_weeks.length
  }
  // console.warn('bones count', count);
  return count
}

export default computeBones