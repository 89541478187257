import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Chart } from "react-google-charts";
import Card from './Card'
import CircularProgress from './CircularProgress'
import theme from '../../theme'

const useStyles = makeStyles((theme) => ({
    root : {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    chart: {
        position: 'relative',
        paddingTop: '50%',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(2),
        },
        '&.size-md': {
            paddingTop: '38%',
        },
        '&.size-sm': {
            paddingTop: '25%',
        },
        '& > div': {
            position: 'absolute',
            top: 0,
            left: '-2.5%',
            width: '110%',
            height: '100%',
            [theme.breakpoints.down("sm")]: {
                left: '2.5%',
                width: '100%',
            },
            [theme.breakpoints.down("xs")]: {
                left: '5%',
                width: '95%',
            },
            '& > div': {
                width: '100%',
                height: '100%',
            }
        }
    }
}))

function formatChartData(data){
    let formatted = [
        ['v', 'h']
    ]
    for (const itm of data) {
        formatted.push([itm.label, itm.value])
    }

    return formatted
}

function ReportLineChart({id, data, xLabel, yLabel, color = theme.palette.green.light}) {
    const classes = useStyles()
    const chartData = formatChartData(data)
    return (
        <Card className={classes.root} hideOverflow>
            <div className={classes.chart}>
                <Chart
                    chartType="LineChart"
                    loader={<CircularProgress />}
                    data={chartData}
                    options={{
                        chartArea: { 
                            width: '80%', 
                            height: '80%', 
                            backgroundColor: 'transparent' 
                        },
                        colors: [color],
                        lineWidth: 4,
                        legend: { position: 'none' },
                        enableInteractivity: false,
                        curveType: 'function',
                        hAxis: {
                            title: xLabel,
                            gridlines: {
                                count: 0,
                                interval: [1]
                            },
                        },
                        vAxis: {
                            title: yLabel,
                            gridlines: {
                                count: 4,
                                interval: [2,4,6,8,10,12]
                            },
                            minValue: 0
                        },
                    }}
                />
            </div>
        </Card>
    )
}

export default ReportLineChart
