import React from 'react'
import { makeStyles } from '@material-ui/core'
import theme from '../../theme'


const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '50%',
        display: 'inline-block',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: theme.spacing(4),
        height: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(3.3),
            height: theme.spacing(3.3),
        },
        '& i': {
            fontSize: theme.spacing(2.5),
            lineHeight: `${ theme.spacing(4) }px`,
            width: theme.spacing(4),
            [theme.breakpoints.down("xs")]: {
                width: theme.spacing(3.3),
                lineHeight: `${ theme.spacing(3) }px`,
                fontSize: theme.spacing(2),
            },
        },
        '& i:before': {
            margin: '0 auto',
        },
        '&.size-lg': {
            // width: theme.spacing(8),
            // height: theme.spacing(8),
            width: `${ theme.spacing(10) }px !important`,
            height: `${ theme.spacing(10) }px !important`,
            [theme.breakpoints.down("md")]: {
                width: `${ theme.spacing(8) }px !important`,
                height: `${ theme.spacing(8) }px !important`,
            },
            '& i': {
                fontSize: theme.spacing(6),
                lineHeight: `${ theme.spacing(10) }px`,
                width: theme.spacing(10),
                [theme.breakpoints.down("md")]: {
                    fontSize: theme.spacing(3.8),
                    lineHeight: `${ theme.spacing(8) }px`,
                    width: theme.spacing(8),
                }
            }
        }
    }
}))

function Icon({ name, size = 'sm', className = '', fg = theme.palette.purple.main, bg = theme.palette.purple.lighter, style = {} }) {
    const classes = useStyles()
    return (
        <div className={`${ classes.root } size-${ size } ${ className }`} style={{ backgroundColor: bg, ...style }}>
            <i className={`icon-${ name }`} style={{ color: fg }}></i>
        </div>
    )
}

export default Icon
