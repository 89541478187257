/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react'
import TopLayout from './TopLayout'
import PageWrap from './PageWrap'
import { UserContextProvider } from '@parallelpublicworks/entitysync'

export const wrapRootElement = ({ element }) => {
  return (
    <UserContextProvider>
      <TopLayout>{element}</TopLayout>
    </UserContextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <PageWrap element={element} props={props} />
}
