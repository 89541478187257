import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core'
import { Card, Image } from '.'
import { math } from 'polished'
import lottie from "lottie-web"
import confetti from "../../../static/lottie-confetti.json"
import { Icon, Typography } from '.'
import theme from '../../theme'
import { ImpactContext } from '../Layout'
import getPetLevels from '../../utils/getPetLevels'
import getPetMaxLevel from '../../utils/getPetMaxLevel'
import getPetLevelStatus from '../../utils/getPetLevelStatus'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        '&.horizontal': {
            display: 'flex',
            flexWrap: 'wrap',
            paddingRight: '36%',
            [theme.breakpoints.down("xs")]: {
                paddingRight: '40%',
            },
            '& .pup-image': {
                width: '40%',
                position: 'absolute',
                bottom: theme.spacing(2),
                right: 0,
                [theme.breakpoints.down("xs")]: {
                    width: '50%',
                }
            },
            '& .MuiBox-root': {
                width: '100%',
                textAlign: 'left',
                position: 'relative',
                '& .MuiTypography-body1': {
                    lineHeight: 1.5,
                    marginBottom: theme.spacing(2)
                }
            },
            '& .progress': {
                '& .bar': {
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginBottom: theme.spacing(1),
                    '& .MuiLinearProgress-root': {
                        width: '75%',
                        [theme.breakpoints.down("xs")]: {
                            width: '60%'
                        }
                    }
                },
            }
        }
    },
    pup: {
        position: 'relative',
        '& > .image': {
            position: 'relative',
            zIndex: 2
        }
    },
    confetti: {
        position: 'absolute',
        top: '-15%',
        left: 0,
        zIndex: 1,
        width: '160%',
        left: '-30%',
        '&.level-1': {
            top: '-40%',
        },
        '&.level-2': {
            top: '-22%',
        },
        '&.level-5': {
            top: '-16%',
            left: '-20%'
        },
    },
    progress: {
        '& .bar': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '& .lvl': {
                width: '20%',
                [theme.breakpoints.down("xs")]: {
                    width: '30%'
                }
            },
            '& .MuiLinearProgress-root': {
                width: '70%',
                height: 12,
                margin: theme.spacing(0, 1.5),
                borderRadius: 12,
                [theme.breakpoints.down("xs")]: {
                    width: '50%'
                }
            },
            '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: theme.palette.light.light
            }
        },
        '&.vertical': {
            '& .bar': {
                marginBottom: theme.spacing(2)
            }
        }
    },
    bodyCard: {
        padding: theme.spacing(10, 2, 3, 2),
        marginTop: -(theme.spacing(8))
    },
    wrapperCard: {
        padding: theme.spacing(3, 0, 3, 3),
    }
}))


function PetProgress({ className, level, progress, steps, help, horizontal, max_level, bonesReqToNewPet }) {
    let bar = typeof progress !== 'undefined' && steps ? Math.ceil(parseInt(progress) * 100 / parseInt(steps)) : null
    if (bar === null) return <></>
    const level_label = (
        <Typography className="lvl" variant="caption">
            {(level < 5 || !horizontal) ? `Level ${ level }` : `${bonesReqToNewPet} Bone${bonesReqToNewPet === 1 ? '' : 's' } to unlock new Pet`}
            {help && <Tooltip title={help} aria-label="help" placement="top">
                <a><Icon name="help" bg="transparent" fg={theme.palette.gray.light} /></a>
            </Tooltip>}
        </Typography>
    )
    return <div className={`progress ${ className }`}>
        <div className="bar">
            {!horizontal && level_label}
            <Icon name="bone" bg={theme.palette.primary.main} fg={theme.palette.light.light} />
            <LinearProgress variant="determinate" value={bar} />
            {level < max_level && <Typography variant="caption">{`${ progress }/${ steps }`}</Typography>}
        </div>
        {horizontal && level_label}
    </div>
}

function BodyCard({ children }) {
    const classes = useStyles()
    return <Card bg="purple-lighter" className={classes.bodyCard}>{children}</Card>
}

function Pet({ id, children, horizontal, help, hideProgress }) {
    const classes = useStyles()
    const comp = horizontal ? WrapperCard : 'div'
    const bodyComp = horizontal ? 'div' : BodyCard
    const levels = getPetLevels()
    const max_level = getPetMaxLevel(levels)
    const { petBones, petLevel, setPetLevel, petType, setPetType } = React.useContext(ImpactContext)
    let lvl_status = getPetLevelStatus(levels, petBones, max_level)
    const [progress, setProgress] = useState(lvl_status.progress)
    const [steps, setSteps] = useState(lvl_status.steps)
    const [celebrate, setCelebrate] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)
    
    const [bonesReqToNewPet, setBonesReqToNewPet] = useState(lvl_status.bonesReqToNewPet)
    const [showNewPetUnlockMessage, setShowNewPetUnlockMessage] = useState(false)

    const progressComp = <PetProgress className={`${ classes.progress } ${ horizontal ? 'horizontal' : 'vertical' }`} level={petLevel} max_level={max_level} progress={progress} steps={steps} help={help} horizontal={horizontal} bonesReqToNewPet={bonesReqToNewPet} />
    const aspect_ratios = [
        2, 1.4, 1.2, 1.1, 1,
        1, 1, 1, 1.25,
        1, 1, 1, 1,
        1, 1, 1.05, 1.25,
        1, 1, 1, 1.05,
        1, 1, 1, 1,
        1, 1, 1, 1.15,
        1, 1, 1, 1.15
    ]

    function WrapperCard({ children, className }) {
        const classes = useStyles()
        return <Card bg="glass" className={`${ classes.root } ${ horizontal ? 'horizontal' : 'vertical' } ${ classes.wrapperCard }`}>{children}</Card>
    }

    useEffect(() => {
        setPetLevel(lvl_status.level)
    }, [])

    useEffect(() => {
        let lvl_status = getPetLevelStatus(levels, petBones, max_level)
        if(petLevel !== lvl_status.level && lvl_status.level > petLevel){
            setPetLevel(lvl_status.level)
            setCelebrate(true)
        }
        
        if(lvl_status.level > 5 && petType !== lvl_status.petType){
            setPetType(lvl_status.petType)
            setShowNewPetUnlockMessage(true)
            setCelebrate(true)
        }

        setSteps(lvl_status.steps)
        setProgress(lvl_status.progress)
        setBonesReqToNewPet(lvl_status.bonesReqToNewPet)

    }, [petBones])


    useEffect(() => {
        if(celebrate){
            const party = lottie.loadAnimation({
                container: document.querySelector(`#pet-celebrate-${ id }`),
                animationData: confetti,
                loop: false,
                autoplay: true
            })
            party.addEventListener('complete', function () {
                party.destroy()
                setCelebrate(false)
            })
        }
    }, [celebrate])

    return (
        <Box component={comp} className={`${ classes.root } ${ horizontal ? 'horizontal' : 'vertical' }`}>
            <div className={`${ classes.pup } pup-image`}>
                <Image className="image" src={`/pup${ petLevel }.png`} aspectRatio={aspect_ratios[(petLevel - 1)]} disableTransition />
                {celebrate && <div className={`${ classes.confetti } level-${ petLevel }`} id={`pet-celebrate-${ id }`}></div>}
            </div>
            <Box className={classes.body} component={bodyComp}>
                {!horizontal && !hideProgress && progressComp}
                {children}
                {horizontal && !hideProgress && progressComp}
                {showNewPetUnlockMessage && <Typography align={!horizontal ? "center" : ""} variant="caption">You have unlocked a new Pet!</Typography>}
            </Box>
        </Box>
    )
}

export default Pet
