
import { DateTime } from 'luxon'
const BEGINING_STREAK = process.env.REACT_APP_BEGINING_STREAK

export default function getStreakStatus(userData) {

  if (!userData) {
    return {
      petBones: 0,
      streaks: 0,
      currentStreaks: 0,
      asthmaCheckinItems: []
    }
  }

  const keys = Object.keys(userData)
  const asthmaCheckin = keys.filter(uuid => {
    const itm = userData[uuid]
    return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type === 'node--asthma_check_in'
  })

  let asthmaCheckinItems = asthmaCheckin.map(uuid => userData[uuid])

  // sort by date
  asthmaCheckinItems.sort((a, b) => {
    return DateTime.fromISO(a.attributes.field_checkin_date_iso).toMillis() - DateTime.fromISO(b.attributes.field_checkin_date_iso).toMillis()
  })

  let iterateDate;

  if(BEGINING_STREAK && DateTime.fromISO(BEGINING_STREAK).isValid) {
    iterateDate = DateTime.fromISO(BEGINING_STREAK)
  } else {
    iterateDate = asthmaCheckinItems[0]?.attributes?.field_checkin_date_iso ? DateTime.fromISO(asthmaCheckinItems[0].attributes.field_checkin_date_iso) : null
  }

  let count = 0;
  let streaks = 0;

  while (iterateDate && iterateDate.startOf("day") <= DateTime.now().startOf("day")) {
    let checkinFound = asthmaCheckinItems.find(item => {
      let itemDateTime = DateTime.fromISO(item.attributes.field_checkin_date_iso)
      return iterateDate.hasSame(itemDateTime, 'day') && item.attributes.field_medication_used == 'yes'
    });

    if(checkinFound) {
      count++;

      if (count === 14) {
        streaks++
        count = 0
      }
    } else if(!checkinFound && DateTime.now().hasSame(iterateDate, 'day')) {
      break;
    } else {
      count = 0;
    }
    iterateDate = iterateDate.plus({ days: 1 })
  }
  
  return {
    petBones: streaks * 3,
    streaks,
    currentStreaks: count,
    asthmaCheckinItems
  }

}