import { fetchAuthenticatedContent } from '@parallelpublicworks/entitysync'

function patchUser(user_id, userContext, attributes) {
  if(userContext && userContext[0] && user_id && userContext[1] && attributes){
    let body = { data: { type: 'user--user', id: user_id, attributes } }
    return fetchAuthenticatedContent(userContext[0].auth, userContext[1], `user/user/${user_id}`, 'PATCH', body).then(resp => {
      if (resp) {
        console.log('User was patched', resp.data);
        userContext[1]({
          type: 'CHANGE_ENTITY',
          data: resp.data,
          save: 'nocache'
        })
        return resp.data
      }else{
        return false
      }
    })
  }else{
    return false
  }
}
export default patchUser