import React from 'react'
import { Link, navigate } from 'gatsby'
import { makeStyles, IconButton } from "@material-ui/core"
import Card from './Card'
import Typography from './Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from './Icon'
import { Button, CircularProgress, Avatar } from '.'
import EditIcon from '@material-ui/icons/Edit'
import theme from '../../theme'
import { ImpactContext } from '../Layout'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
        '&.edit-button': {
            position: 'relative',
            '& header': {
                paddingRight: theme.spacing(3)
            }
        },
        '& + .section': {
            marginTop: theme.spacing(2)
        }
    },
    edit: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(1),
            right: theme.spacing(1),
        }
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '& > *': {
            '& + *': {
                marginLeft: theme.spacing(2),
            }
        }
    },
    avatar: {
        marginLeft: theme.spacing(1),
        width: 40,
        height: 40,
        [theme.breakpoints.down("md")]: {
            width: 30,
            height: 30
        },
        [theme.breakpoints.down("xs")]: {
            width: 25,
            height: 25
        }
    },
    heading: {
        fontSize: "1em",
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        '& > *:not(:last-child)': {
            marginTop: 0,
            marginBottom: theme.spacing(2)
        },
        '& ul': {
            paddingLeft: theme.spacing(3),
            '& li': {
                '&::marker': {
                    color: theme.palette.purple.lighter
                }
            }
        }
    },
    footer: {
        display: 'flex',
        '& > *': {
            marginLeft: 0,
            marginRight: 0,
        },
        '&.cols-2': {
            alignItems: 'center',
            '& > *': {
                '&:first-child': {
                    paddingRight: theme.spacing(1),
                    // maxWidth: 200,
                },
                '&:last-child': {
                    paddingLeft: theme.spacing(1),
                    textAlign: 'center',
                    // maxWidth: 200
                }
            }
        },
        '&.cols-1': {
            '& > *': {
                width: '100%',
                textAlign: 'left'
            }
        },
    }
}))


function Section({ icon = null, title, children, footer, actionLabel, actionOnClick, actionTo, actionDisabled, editOnClick, editTo, help, bg = 'white', uuid, backTo = '/', profiles }) {
    const classes = useStyles()
    const { userData } = React.useContext(ImpactContext)

    if(userData === null) return <CircularProgress />

    const has_footer = typeof footer !== 'undefined' || typeof actionLabel !== 'undefined'
    const footer_columns = typeof footer !== 'undefined' && typeof actionLabel !== 'undefined' ? 2 : 1
    const action_attrs = {}
    if (typeof actionTo !== 'undefined') {
        action_attrs.component = Link
        if(typeof uuid !== 'undefined'){
            action_attrs.onClick = (e) => {
                navigate(actionTo, { state: { response: userData[uuid], backTo } })
            }
        }else{
            action_attrs.to = actionTo
        }
    } else if (typeof actionOnClick !== 'undefined') {
        action_attrs.onClick = actionOnClick
    }
    const action = typeof actionLabel !== 'undefined' ? <div><Button variant="outlined" color="primary" size="small" {...action_attrs}>{actionLabel}</Button></div> : null
    let has_edit_button
    const edit_attrs = {}
    if (typeof editTo !== 'undefined') {
        has_edit_button = true
        if(typeof uuid !== 'undefined'){
            edit_attrs.onClick = (e) => {
                navigate(editTo, { state: { response: userData[uuid], backTo } })
            }
        }else{
            edit_attrs.component = Link
            edit_attrs.to = editTo
        }
    } else if (typeof editOnClick !== 'undefined') {
        has_edit_button = true
        edit_attrs.onClick = editOnClick
    }

    let avatars = null;
    if(Array.isArray(profiles) && profiles.length > 0){
        avatars = profiles.map(profile => <Avatar tooltip className={classes.avatar} key={profile.id} profile={profile} />)
    }

    let title_label = null
    if(typeof title !== 'undefined'){
        if(typeof help !== 'undefined'){
            title_label = (
                <>
                    <Typography variant="h1" align="left" shade="dark" uppercase className={classes.heading}>
                        {title}
                        <Tooltip title={help} aria-label="help" placement="top">
                            <a><Icon name="help" bg="transparent" fg={theme.palette.gray.dark} /></a>
                        </Tooltip>
                        {avatars}
                    </Typography>
                </>
            )
        }else{
            title_label = <Typography variant="h1" align="left" shade="dark" uppercase className={classes.heading}>{title} {avatars}</Typography>
        }
    }

    return (
        <Card className={`section ${ classes.root } ${ has_edit_button ? 'edit-button' : '' }`} bg={bg} >
            {has_edit_button && (
                <IconButton color="primary" {...edit_attrs} className={classes.edit}>
                    <EditIcon />
                </IconButton>
            )}
            {title_label !== null && (
                <header className={classes.header}>
                    {icon}
                    {title_label}
                </header>
            )}
            <div className={classes.body}>
                {children}
                {has_footer && (
                    <footer className={`${ classes.footer } cols-${ footer_columns }`}>
                        {action}
                        {footer}
                    </footer>
                )}
            </div>
        </Card>
    )
}

export default Section
