import { DateTime } from "luxon"

function getSpirometries(userData) {
    let spiros_data = Object.keys(userData).filter(uuid => userData[uuid].type === 'node--spirometer_result' && typeof userData[uuid].attributes !== 'undefined' && userData[uuid].attributes.field_test_date_iso)
    spiros_data = spiros_data.map(cact_uuid => userData[cact_uuid]).filter(cact => typeof cact.attributes !== 'undefined').sort((a, b) => {
        const a_date = DateTime.fromISO(a.attributes.field_test_date_iso)
        const b_date = DateTime.fromISO(b.attributes.field_test_date_iso)
        return b_date.toMillis() - a_date.toMillis() 
    })
    let spiros_by_day = {}
    for (const spiro of spiros_data) {
        const dt = DateTime.fromISO(spiro.attributes.field_test_date_iso)
        const day_str = dt.toFormat('yyyy LLL dd')
        spiros_by_day[day_str] = spiro
    }
    const spiros_days = Object.keys(spiros_by_day)
    const spiros = spiros_days.map((day_str) => spiros_by_day[day_str])
    return spiros
}

export default getSpirometries