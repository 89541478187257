import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { Home, People, Assignment, TrackChanges } from '@material-ui/icons'
import { makeStyles, IconButton } from '@material-ui/core'
import { Avatar } from './library'
import useWindowSize from '../utils/useWindowSize'
import patchUser from '../utils/patchUser'
import theme from '../theme'
import { Card, Typography, Button } from './library'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: '6em',
    boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
    zIndex: 99
  },
  button: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      height: '1.2em',
      width: '1.2em'
    },
    '&.Mui-selected': {
      paddingTop: 0,
      '& .MuiSvgIcon-root': {
        fill: 'url(#icon-gradient) #9D5DD2'
      },
      '& .MuiBottomNavigationAction-label': {
        background: '-webkit-linear-gradient(15deg, #BE62D6, #7A58CE)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontWeight: 'bold'
      }
    }
  },
  demoOverlay: {
    // backgroundColor: hexToRgbA('#000', 0.7),
    backgroundImage: 'url(/demo-overlay.svg)',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center center',
    opacity: 0.5,
    width: 4096,
    height: 4096,
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: -2048,
    marginLeft: -2048,
    // transition: 'all 0.3s ease-out',
    zIndex: 99,
    '&.hidden': {
      display: 'none'
    }
  },
  demoPopover: {
    position: 'fixed',
    left: '50%',
    zIndex: 100,
    padding: theme.spacing(2),
    opacity: 1,
    '&.hidden': {
      display: 'none',
      opacity: 0
    }
  },
  demoCard: {
    padding: theme.spacing(2, 3),
    '& .MuiTypography-root': {
      '&:first-child': {
        paddingRight: theme.spacing(10)
      },
      '& + .MuiTypography-root': {
        marginTop: theme.spacing(1)
      }
    }
  },
  demoNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& .MuiButtonBase-root': {
      minWidth: 0,
      '&.MuiButton-text': {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    }
  },
  demoDotNav: {
    color: theme.palette.light.light,
    padding: 5,
    opacity: 0.5,
    '&.active, &:hover, &:focus, &:active': {
      opacity: 1
    },
    '& .MuiSvgIcon-root': {
      width: 12,
      height: 12
    }
  },
  demoAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    position: 'absolute',
    top: -10,
    right: theme.spacing(4)
  },
  tip: {
    width: 'calc(100% + 32px)',
    // backgroundColor: 'red',
    height: theme.spacing(2),
    display: 'block',
    textAlign: 'center',
    marginLeft: -16,
    '& i': {
      width: '25%',
      height: theme.spacing(2),
      display: 'block',
      // backgroundColor: 'green',
      transform: 'translateY(-28px)',
      '& b': {
        width: theme.spacing(5),
        height: theme.spacing(5),
        display: 'block',
        margin: '0 auto',
        backgroundColor: theme.palette.primary.light,
        transform: 'rotate(-45deg)'
      }
    },
    '&.pos-1': {
      '& i': {
        marginLeft: '25%'
      }
    },
    '&.pos-2': {
      '& i': {
        marginLeft: '50%'
      }
    },
    '&.pos-3': {
      '& i': {
        marginLeft: '75%'
      }
    }
  }
}))

const demo_data = [
  {
    title: 'Welcome to IMPACT!',
    description:
      'Hi, I’m Charlie. This is your app dashboard, where you will see tasks that are due and you can check on your asthma status.'
  },
  {
    title: 'Reports',
    description: 'Under reports, you can review symptom reports, asthma control scores, and spirometry test data.'
  },
  {
    title: 'Goals',
    description: 'Help me grow by setting goals and doing weekly check-ins. You can review your goals here.'
  },
  {
    title: 'Account',
    description: 'Set up your names, avatars, and notifications in the account page.'
  }
]

export const BottomNav = ({ value = '', setShowDemo, showDemo, user, userContext, setShowDisclaimer }) => {
  const classes = useStyles()
  const size = useWindowSize()
  const a_button = useRef(null)
  const total_buttons = 4
  const [spotSize, setSpotSize] = useState(101)
  const [demoTranslate, setDemoTranslate] = useState('0px, 0px')
  const [currentDemoPosition, setCurrentDemoPosition] = useState(0)
  const [popoverWidth, setPopoverWidth] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cw = a_button.current.clientWidth
    setPopoverWidth(cw * total_buttons)
    let xpos = 0
    switch (currentDemoPosition) {
      case 0:
        xpos = -(cw * 1.5 + theme.spacing(0.5))
        break
      case 1:
        xpos = -(cw * 0.5 + theme.spacing(0.5))
        break
      case 2:
        xpos = cw * 0.5 - theme.spacing(0.5)
        break
      case 3:
        xpos = cw * 1.5 - theme.spacing(0.5)
        break
    }

    setSpotSize(size.width < 960 ? 88 : 101)
    setDemoTranslate(`${xpos}px, ${(size.height - spotSize) / 2}px`)
  }, [size, currentDemoPosition])

  function demoNext() {
    let nextPos = currentDemoPosition + 1
    if (nextPos > 3) {
      nextPos = 0
    }
    setCurrentDemoPosition(nextPos)
  }

  async function onSkip() {
    setLoading(true)
    await patchUser(user?.id, userContext, {field_demo_skip: true})
    setShowDemo(false)
    setShowDisclaimer(true)
  }

  const demoDots = []
  for (let index = 0; index < total_buttons; index++) {
    demoDots.push(
      <IconButton
        key={`demo-dot-${index}`}
        className={`${classes.demoDotNav} ${currentDemoPosition === index ? 'active' : ''}`}
        onClick={() => setCurrentDemoPosition(index)}
      >
        <FiberManualRecordIcon />
      </IconButton>
    )
  }

  let profile = { name: [''], avatar: '' }

  return (
    <>
      <BottomNavigation className={classes.root} value={value} showLabels>
        <BottomNavigationAction
          className={`${classes.button} ${currentDemoPosition === 0 && showDemo ? 'Mui-selected' : ''}`}
          onClick={() => navigate('/')}
          value="home"
          label="Home"
          icon={<Home />}
          ref={a_button}
        />
        <BottomNavigationAction
          className={`${classes.button} ${currentDemoPosition === 1 && showDemo ? 'Mui-selected' : ''}`}
          onClick={() => navigate('/reports')}
          value="reports"
          label="Reports"
          icon={<Assignment />}
        />
        <BottomNavigationAction
          className={`${classes.button} ${currentDemoPosition === 2 && showDemo ? 'Mui-selected' : ''}`}
          onClick={() => navigate('/goals')}
          value="goals"
          label="Goals"
          icon={<TrackChanges />}
        />
        <BottomNavigationAction
          className={`${classes.button} ${currentDemoPosition === 3 && showDemo ? 'Mui-selected' : ''}`}
          onClick={() => navigate('/account')}
          value="account"
          label="Account"
          icon={<People />}
        />
      </BottomNavigation>
    
        {typeof size.width !== 'undefined' && showDemo && (
          <>
            <div className={`${classes.demoPopover}`} style={{ width: popoverWidth, bottom: spotSize, marginLeft: -(popoverWidth / 2) }}>
              <Avatar className={classes.demoAvatar} profile={profile} />
              <Card bg="purple" className={classes.demoCard}>
                <Typography color="light" shade="light" variant="body2">
                  {demo_data[currentDemoPosition].title}
                </Typography>
                <Typography color="light" variant="caption">
                  {demo_data[currentDemoPosition].description}
                </Typography>
                <footer className={classes.demoNav}>
                  <Button onClick={onSkip} disabled={loading} inline style={{ color: theme.palette.light.light }}>SKIP</Button>
                  <div>{demoDots}</div>
                  {currentDemoPosition < 3 && (
                    <Button onClick={e => demoNext()} inline variant="contained">
                      NEXT
                    </Button>
                  )}
                  {currentDemoPosition === 3 && (
                    <Button disabled={loading} onClick={onSkip} inline variant="contained">SET UP</Button>
                  )}
                </footer>
              </Card>
              <span className={`${classes.tip} pos-${currentDemoPosition}`}>
                <i>
                  <b />
                </i>
              </span>
            </div>
            <div className={`${classes.demoOverlay}`} style={{ transform: `translate(${demoTranslate})` }}></div>
          </>
        )}
  

      <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
        <linearGradient id="icon-gradient">
          <stop offset="0%" stopColor="#BE62D6" />
          <stop offset="65%" stopColor="#9D5DD2" />
          <stop offset="100%" stopColor="#7A58CE" />
        </linearGradient>
      </svg>
    </>
  )
}