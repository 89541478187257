import {DateTime} from 'luxon'

function getWeekGoals(userData, week_number = 0){
    if(!userData) return []
    const filterGoals = (uuid) => {
        if(userData[uuid].type && userData[uuid].type.includes('goal') && typeof userData[uuid].attributes !== 'undefined'){
            return userData[uuid].attributes.field_goal_week === `week${week_number}`
        }else{
            return false
        }
    }
    return Object.keys(userData).filter(filterGoals)
}
export default getWeekGoals