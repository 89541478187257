import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'

export const animals = [
    'bear',
    'cat',
    'fox',
    'gorilla',
    'jiraffe',
    'kangaroo',
    'koala',
    'llama',
    'panda',
    'pug',
    'rabbit',
    'racoon',
    'reindeer',
    'skunk',
    'squirrel',
    'wolf'
]


function AvatarComponent({ profile, className, tooltip }) {
    const animal = profile.avatar ? profile.avatar : null
    const src = animal ? `/avatars/avatar-${ animal }.png` : `/avatars/avatar-pup1.png`
    const initials = !animal ? profile.name[0] : null

    const avatar = <Avatar src={src} className={className}>
        <>{initials}</>
    </Avatar>
    if (tooltip) {
        return (
            <Tooltip title={profile.name} placement="top">
                {avatar}
            </Tooltip>
        )
    }
    return avatar
}

export default AvatarComponent
