import { DateTime } from "luxon"

function getCACTS(userData) {
    let cacts_data = Object.keys(userData).filter(uuid => userData[uuid].type === 'node--cact_response')
    cacts_data = cacts_data.map(cact_uuid => userData[cact_uuid]).filter(cact => typeof cact.attributes !== 'undefined').sort((a, b) => {
        return DateTime.fromISO(b.attributes.field_test_date_iso).toMillis() - DateTime.fromISO(a.attributes.field_test_date_iso).toMillis() 
    })
    let cacts_by_day = {}
    for (const cact of cacts_data) {
        const day_str = DateTime.fromISO(cact.attributes.field_test_date_iso).toFormat('yyyy LLL dd')
        cacts_by_day[day_str] = cact
    }
    const cacts_days = Object.keys(cacts_by_day)
    const cacts = cacts_days.map((day_str) => cacts_by_day[day_str])
    return cacts
}

export default getCACTS