import React, { useContext, useState, createContext, useEffect, useRef }  from 'react'
import { DateTime } from 'luxon'
import { useBeforeunload } from 'react-beforeunload';
import styled from '@emotion/styled'
import { UserContext, isLoggedIn, handleLogout, fetchAuthenticatedContent } from "@parallelpublicworks/entitysync"
import { makeStyles } from '@material-ui/core/styles'
import { BottomNav } from './BottomNav'
import Login from './Login'
import { Button, CircularProgress, Dialog, Logo, Slideshow, Card, Image, Typography, Alert, Icon, Pet } from './library'
import getProfiles from '../utils/getProfiles'
import calcWeeks from '../utils/calcWeeks'
import hexToRgbA from '../utils/hexToRgbA'
import fetchNodes from '../utils/fetchNodes'
import getTodaysCheckIn from '../utils/getTodaysCheckIn'
import patchUser from '../utils/patchUser'
import postEntity from '../utils/postEntity'
import computeBones from '../utils/computeBones'
import getStreakStatus from '../utils/getStreakStatus'
import { navigate } from 'gatsby'
import theme from '../theme'

export const ImpactContext = createContext({})

const useStyles = makeStyles((theme) => ({
  onboarding: {
    padding: theme.spacing(4, 0),
    paddingTop: 0
  }
}));

const PageWrapper = styled.div`
  display: block;
  padding-bottom: ${ props => props.bottomNav ? '8em' : 0 }
`

function findAncestorPath(path) {
  const main_paths = ['home', 'reports', 'goals', 'account']
  const parts = path.split("/")
  if (parts.length && !parts[1].length) {
    return 'home'
  } else {
    const ancestor = parts.length && main_paths.includes(parts[1]) ? parts[1] : null
    return ancestor
  }
}


const gettingStartedConfig = [
  {key: 'wearable', label: 'Pair Symptom Watch'},
  {key: 'goal', label: 'Select Goals'},
  {key: 'cact', label: 'Asthma Control Test'},
  {key: 'spiro', label: 'Spirometry Test'},
]

const wearableInitialAttrs = {
  title: "Connecting your device...",
  description: "Select the wearable from the Bluetooth device list, called 'Puck'. Make sure the wearable is nearby."
}


function Layout({children, location, path}) {
    const classes = useStyles();
    const ancestor_path = findAncestorPath(path)
    const userContext = useContext(UserContext)
    const today = DateTime.now()
    const hour = new Date().getHours();
    const [loading, setLoading] = useState(null)
    const [userData, setUserData] = useState(null)
    const [user, setUser] = useState(null)
    const keepAlive = useRef(null)
    const pinging = useRef(false)

    const [onboardingSeen, setOnboardingSeen] = useState(false)
    const [showBottomNav, setShowBottomNav] = useState(false)
    const [showDemo, setShowDemo] = useState(null)
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    
    const [petBones, setPetBones] = useState(0)
    const [petLevel, setPetLevel] = useState(1)
    const [petType, setPetType] = useState('charlie')
    const [bonesDialog, setBonesDialog] = useState(null)
    const [bonesDialogOverAll, setBonesDialogOverAll] = useState(false)
    const [bonesDialogOpen, setBonesDialogOpen] = useState(false)
    // const [grantBonus, setGrantBonus] = useState(null)
    const [triggerBonesDialog, setTriggerBonesDialog] = useState(0)

    const [currentWeek, setCurrentWeek] = useState(null)
    const [previousWeek, setPreviousWeek] = useState(null)
    const [isPastWeek, setIsPastWeek] = useState(false)
    const [isFutureStartDate, setIsFutureStartDate] = useState(false)
    const [showFutureDialog, setShowFutureDialog] = useState(false)
    const [weeks, setWeeks] = useState(null)
    const [profiles, setProfiles] = useState(null)
    
    const [todaysCheckIn, setTodaysCheckIn] = useState(null)
    const [showOutstandingAlert, setShowOutstandingAlert] = useState(false)
    const [wearableWasPaired, setWearableWasPaired] = useState(null)
    const [gettingStarted, setGettingStarted] = useState([])
    const [gettingStartedDone, setGettingStartedDone] = useState(false)
    
    const [savingDailyCheckInGoal, setSavingDailyCheckInGoal] = useState(false)
    const [weekWithoutDailyCheckinGoal, setWeekWithoutDailyCheckinGoal] = useState(null)

    const [currentStreaks, setCurrentStreaks] = useState(0)
    const [asthmaCheckinItems, setAsthmaCheckinItems] = useState([])
    const [streaks, setStreaks] = useState(null)

    useEffect(() => {
      setPetBones(computeBones(user, userData, weeks))
    }, [user, userData, weeks])

    useBeforeunload((event) => {
      if(isLoggedIn(userContext)){
        handleLogout(userContext)
      }
    });

    useEffect(() => {
      
      function pingFn(){
        // console.log('pingFn');
        return fetchAuthenticatedContent(userContext[0].auth, userContext[1], 'node/page', 'GET')
        .then(resp => {
          // console.log('ping resp', resp);
          return resp      
        })
      }
      
      if(loading === false && isLoggedIn(userContext) && !path.includes('logout') && keepAlive.current === null){
        // console.warn('INITIALIZING KEEP ALIVE');
        keepAlive.current = setInterval(() => {
          (async () => {
            if(!pinging.current){
              pinging.current = true
              // console.warn('keeping connection alive');
              const ping = await pingFn()
              if(!ping){
                console.error('ping ERROR, logging out...');
                handleLogout(userContext)
                if(typeof window !== 'undefined'){
                  // window.location.replace('/login')
                }
              }else{
                console.log('ping OK');
                pinging.current = false
              }
            }
          })()
        }, 10000)
      }

    }, [userContext, loading])

    useEffect(() => {
      async function pullData() {
        if (!location.action) {
          const udata = userContext && userContext[0] && typeof userContext[0].userData ? userContext[0].userData : []
          const keys = Object.keys(udata)
          if(keys.length > 0){
            setLoading(true)
            const user_id = keys.find(an_id => {
              const itm = udata[an_id]
              return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type === 'user--user'
            })
            if (user_id) {
              await fetchNodes('goal', user_id, userContext)
              await fetchNodes('asthma_check_in', user_id, userContext)
              await fetchNodes('asthma_check_in_goal', user_id, userContext)
              await fetchNodes('cact_response', user_id, userContext)
              await fetchNodes('spirometer_result', user_id, userContext)
              await fetchNodes('notification', user_id, userContext)
            }
            setLoading(false)
          }
        }
      }
      if (loading === null && isLoggedIn(userContext) && !path.includes('logout')) {
        pullData()
      }  
    }, [userContext, loading])

    useEffect(() => {
      function populateAsthmaCheckInGoal(){
        setSavingDailyCheckInGoal(true)
        console.warn(`autopopulating asthma_check_in_goal for week ${weekWithoutDailyCheckinGoal.number + 1}`);
        postEntity(
          userContext, 
          'asthma_check_in_goal', 
          {
            title: `Week ${weekWithoutDailyCheckinGoal.number + 1} Daily Check-in Goal`, 
            field_goal_week: `week${weekWithoutDailyCheckinGoal.number}`, 
            field_check_in_frequency: 3
          }
        )
      }
      if(weekWithoutDailyCheckinGoal !== null && user?.id && currentWeek !== null && weeks !== null && !savingDailyCheckInGoal){
        populateAsthmaCheckInGoal()
      }
    }, [userContext, user, weekWithoutDailyCheckinGoal])

    /*
    useEffect(() => {
      if(grantBonus !== null){
        // console.warn('grantBonus', grantBonus);
        let action_label ='Ok' 
        let action_to
        if(weeks && grantBonus < weeks.length - 1){
          action_label = `Set goals for week ${grantBonus + 2}`
          action_to ='/goals'
        }
        const obj = {
          description: `Great job finishing your week ${grantBonus + 1} to do list! Keep it up!`,
          title: 'You earned 1 bone!',
          actionLabel: action_label,
          id: DateTime.now().toISO(),
          overAll: true,
          bones: 1,
          addBonus: true,
          week_num: grantBonus
        }
        if(action_to){
          obj.actionTo = action_to
        }
        setBonesDialog(obj)
        setTriggerBonesDialog(triggerBonesDialog + 1)
      }
    }, [grantBonus])
    */
    
    useEffect(() => {
      if(isLoggedIn(userContext) && loading === false){
        setUserData(userContext && userContext[0] && typeof userContext[0].userData ? userContext[0].userData : null)
      }
    }, [userContext, loading])
  
    useEffect(() => {
      // console.warn('USER DATA CHANGED', userData);
      if (userData !== null && isLoggedIn(userContext) && loading !== null) {
        const keys = Object.keys(userData)
        const uuid = keys.find(an_id => {
            const itm = userData[an_id];
            return typeof itm.id !== 'undefined' && typeof itm.attributes !== 'undefined' && itm.type === 'user--user'
        })
        setTodaysCheckIn(getTodaysCheckIn(userData))
        
        let { currentStreaks, asthmaCheckinItems, streaks: streaksCount } = getStreakStatus(userData)

        if(streaks != null && streaksCount > streaks) {
          setBonesDialog({...bonesDialog, title: '14 days medication streak!', description: "You earned 3x bones for completing a streak! Keep it up!" })
        }

        setCurrentStreaks(currentStreaks);
        setAsthmaCheckinItems(asthmaCheckinItems);
        setStreaks(streaksCount);

        if(uuid){
          setUser(userData[uuid])
        }
      }
    }, [userData, loading])

    useEffect(() => {
      if(user && loading !== null){
        if(user.attributes.field_onboarding_seen === true){
          setOnboardingSeen(true)
        }
        if(user.attributes.field_demo_skip === true){
          setShowDemo(false)
        }else if(showDemo === null){
          setShowDemo(true)
        }
        if(user.attributes.field_disclaimer_accept === true){
          setShowDisclaimer(false)
        }
        setProfiles(getProfiles(user, petBones))
        if(user?.attributes?.field_wearable_on === true){
          setWearableWasPaired(true)
        }
      }
    }, [user, loading, userData])
    
    useEffect(() => {   
      if (user !== null) {
        const weeksCalc = calcWeeks(user, userData, today)
        if(weeksCalc){
          setWeeks(weeksCalc)
        }
      }
    }, [user, userData])

    useEffect(() => {
      if(weeks !== null){
        let current_week = weeks.find(wk => wk.interval.contains(today))
        setIsFutureStartDate(today < weeks[0].interval.s)
        if(!current_week) current_week = weeks[0]
        setCurrentWeek(current_week)
        if(current_week.number > 0){
          setPreviousWeek(weeks[current_week.number - 1])
        }
      }
    }, [weeks])

    useEffect(() => {
      if(isFutureStartDate && !location.pathname.includes('/account')){
        setShowFutureDialog(true)
      }else{
        setShowFutureDialog(false)
      }
    }, [isFutureStartDate, location])

    useEffect(() => {
      if(isPastWeek){
        if(
          location.pathname === '/' || 
          location.pathname === '/goals' || 
          location.pathname === '/account' || 
          location.pathname.includes('/reports')
        ){
          setShowOutstandingAlert(true)
        }else{
          setShowOutstandingAlert(false)
        }
        if(
          location.pathname.includes('/import-symptoms') ||
          location.pathname.includes('/asthma-check-in-form') ||
          location.pathname.includes('/goal-form')
        ){
          navigate('/')
        }
      }else{
        setShowOutstandingAlert(false)
      }
    }, [isPastWeek, location])

    useEffect(() => {
      setShowBottomNav(ancestor_path !== null && onboardingSeen === true)
    }, [onboardingSeen, location])

    useEffect(() => {
      displayBonesDialog()
    }, [currentWeek, location, triggerBonesDialog])

    useEffect(() => {
      setBonesDialogOpen(bonesDialog !== null)
    }, [bonesDialog])

    useEffect(() => {
      let done = []
      if(wearableWasPaired === true) done.push('wearable')
      if(userData !== null){        
        const goals = Object.keys(userData).filter(uuid => userData[uuid].type === 'node--goal' && typeof userData[uuid].attributes !== 'undefined')
        if(goals.length > 0) done.push('goal')
        const cacts = Object.keys(userData).filter(uuid => userData[uuid].type === 'node--cact_response' && typeof userData[uuid].attributes !== 'undefined')
        if(cacts.length > 0) done.push('cact')
        const spiros = Object.keys(userData).filter(uuid => userData[uuid].type === 'node--spirometer_result' && typeof userData[uuid].attributes !== 'undefined')
        if(spiros.length > 0) done.push('spiro')
      }
      setGettingStarted(done)
    }, [wearableWasPaired, userData])

    useEffect(() => {
      setGettingStartedDone(gettingStarted.length === gettingStartedConfig.length)
    }, [gettingStarted])


    useEffect(() => {
      if(currentWeek !== null && weeks !== null){
        if(!currentWeek.goals_daily_check_in_goal_exists){
          setWeekWithoutDailyCheckinGoal(currentWeek)
        }else if(currentWeek.todo_done && typeof weeks[currentWeek.number + 1] !== 'undefined' && !weeks[currentWeek.number + 1]['goals_daily_check_in_goal_exists']){
          setWeekWithoutDailyCheckinGoal(weeks[currentWeek.number + 1])
        }else{
          setWeekWithoutDailyCheckinGoal(null)
        }
      }
    }, [currentWeek, weeks])

    function displayBonesDialog(){
      if(typeof window !== 'undefined' && bonesDialog === null){
        const add_bone = localStorage.getItem('add_bone')
        if(add_bone){
          const bones_dialog = JSON.parse(add_bone)
          if(typeof bones_dialog.overAll !== 'undefined'){
            setBonesDialogOverAll(bones_dialog.overAll)
          }else{
            setBonesDialogOverAll(
              location.pathname.includes('form') ||
              location.pathname.includes('detail') ||
              location.pathname.includes('check-in') ||
              location.pathname.includes('import')
            )
          }
          setBonesDialog(bones_dialog)
        }
      }
    }

    function onDisclaimerAccept(){
      setShowDisclaimer(false)
      patchUser(user?.id, userContext, {field_disclaimer_accept: true})
    }

    function closeBonesDialog(){
      if(typeof window !== 'undefined'){
        localStorage.removeItem('add_bone')
      }
      setBonesDialog(null)
    }

    if(!isLoggedIn(userContext) || (isLoggedIn(userContext) && user === null && loading === null)) {
      return <Login location={location} />
    }

    if(path.includes('logout') || path.includes('login')) return <>{children}</>
    
    
    if(user === null || loading === null || loading === true) return <><CircularProgress /></>

    if(user !== null && onboardingSeen === false) return (
      <div className={classes.onboarding}>
        <Logo />
        <Slideshow user={user} userContext={userContext} today={today} setOnboardingSeen={setOnboardingSeen} setShowDemo={setShowDemo}>
          <Card hideOverflow>
              <Image src="/onboarding-slide-0.jpg" />
              <Typography variant="caption" align="center">
                  A watch-like wearable device that allows your child to report their asthma symptoms real-time.
              </Typography>
          </Card>
          <Card hideOverflow>
              <Image src="/onboarding-slide-1.jpg" />
              <Typography variant="caption" align="center">
                  Monitor your child’s lung function using a hand-held spirometer
              </Typography>
          </Card>
          <Card hideOverflow>
              <Image src="/onboarding-slide-2.jpg" />
              <Typography variant="caption" align="center">
                  Work together with your child to track their asthma symptoms and asthma control through a companion mobile app
              </Typography>
          </Card>
          <Card hideOverflow>
              <Image src="/onboarding-slide-3.jpg" />
              <Typography variant="caption" align="center">
                  Set goals with your child, earn rewards, and improve asthma care together!
              </Typography>
          </Card>
        </Slideshow>
      </div>
    )

    return (
      <ImpactContext.Provider value={{userContext, userData, user, setUser, onboardingSeen, ancestor_path, petBones, currentWeek, weeks, profiles, today, hour, todaysCheckIn, isPastWeek, petLevel, setPetLevel, bonesDialogOpen, closeBonesDialog, wearableInitialAttrs, gettingStartedDone, gettingStarted, gettingStartedConfig, previousWeek, petType, setPetType, streaks, currentStreaks, asthmaCheckinItems}}>
          <PageWrapper id="page" bottomNav={showBottomNav} >
            <>
              {showOutstandingAlert && (
                <Alert 
                  color="orange" 
                  icon={<Icon name="attention" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="md" />}
                  title={`Psst... You have outstanding tasks from week ${currentWeek.number + 1}`}
                  to="/weekly-to-do"
                  alignMiddle
                  squared
                />
              )}
              {children}        
              {showDemo === false && (
                  <Dialog
                    id="impact-disclaimer"
                    open={showDisclaimer}
                    title="Disclaimer"
                    description="The IMPACT application does not replace the advice of a health care provider"
                    entitySubmit={<Button onClick={onDisclaimerAccept} color="primary" variant="contained">Accept</Button>}
                  />
              )}
          
              <Dialog
                  id="after-check-dialog"
                  open={bonesDialogOpen}
                  title={bonesDialog ? bonesDialog.title : ''}
                  handleClose={closeBonesDialog}
                  actionClick={bonesDialog && bonesDialog.actionTo ? null : closeBonesDialog}
                  actionLabel={bonesDialog ? bonesDialog.actionLabel : 'Ok'}
                  actionTo={bonesDialog && bonesDialog.actionTo ? bonesDialog.actionTo : null}
                  overAll={bonesDialogOverAll}
              >
                  <Pet
                    id="a-pet"
                    hideProgress={bonesDialog && typeof bonesDialog.bones === 'undefined'}
                  >
                      {bonesDialog && typeof bonesDialog.description !== 'undefined' && <Typography variant="body1" align="center">{bonesDialog.description} {typeof bonesDialog.boneIcon !== 'undefined' && bonesDialog.boneIcon && <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" />}</Typography>}
                  </Pet>
              </Dialog>
              {weeks !== null && (
                <Dialog 
                  id="future-dialog"
                  title="Hold on"
                  description={`Your start date of participation is set to ${weeks[0].interval.s.toLocaleString(DateTime.DATE_SHORT)}.`}
                  actionTo="/account"
                  actionLabel="Set a different date"
                  open={showFutureDialog}
                />
              )}
              {showBottomNav && <BottomNav value={ancestor_path} setShowDemo={setShowDemo} showDemo={showDemo} user={user} userContext={userContext} setShowDisclaimer={setShowDisclaimer} />}
            </>
          </PageWrapper>
      </ImpactContext.Provider>
    )
}

export default Layout
