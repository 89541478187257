import React from 'react'
import getColorFromLevel from '../../utils/getColorFromLevel'
import { Section, Typography } from '.'
import { palette_scale } from '../../theme'
import { makeStyles } from '@material-ui/core'
import getMoodOptions from '../../utils/getMoodOptions'
import getNameWithPossessiveApostrophe from '../../utils/getNameWithPossessiveApostrophe'


const useStyles = makeStyles((theme) => ({
    mood: {
        '& i': {
            fontSize: '180%',
            lineHeight: 1,
            verticalAlign: 'bottom',
            '&:before': {
                marginLeft: 0
            }
        }
    }
}))


function ReportSurvey({ profile, value }) {
    const classes = useStyles()
    const color = getColorFromLevel(value)
    const name_label = getNameWithPossessiveApostrophe(profile)
    let mood = getMoodOptions().find((op) => op.value === value)
    return (
        <Section>
            <Typography variant="body1" align="left">{`How's ${ name_label } asthma today?`}</Typography>
            <Typography variant="body2" align="left" color={color} className={classes.mood}>{mood.label}</Typography>
        </Section>
    )
}

export default ReportSurvey
