import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography } from '.';
import { Link } from 'gatsby';
import slugify from '../../utils/slugify';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        padding: `${theme.spacing(6)}px 0 ${theme.spacing(2)}px`,
        fontSize: '1.3em'
    },
    paper: {
        boxShadow: 'none'
    }
}));

function TabsComponent({ id, title, tabs, variant = 'fullWidth', initialTab = 0 }) {

    const classes = useStyles();
    const [value, setValue] = React.useState(initialTab);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const scrollButtons = variant === 'scrollable' ? 'on' : 'off';

    if (!Array.isArray(tabs)) {
        return <></>
    }

    return (
        <Paper square className={classes.paper}>
            {typeof title !== 'undefined' && <Typography variant="h1" shade="dark" className={classes.title}>{title}</Typography>}
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                variant={variant}
                scrollButtons={scrollButtons}
            >
                {tabs.map(tab => {
                    const attrs = {};
                    if (typeof tab.to !== 'undefined') {
                        attrs.component = Link
                        attrs.to = tab.to
                    } else if (typeof tab.onClick !== 'undefined') {
                        attrs.onClick = tab.onClick
                    }
                    return <Tab label={tab.label} key={`id-${id}-${slugify(tab.label)}`} disabled={tab.disabled} {...attrs} />
                })}
            </Tabs>
        </Paper>
    )
}

export default TabsComponent
