import React from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import Layout from '../../src/components/Layout'

function PageWrap({ element, props }) {
  return (
    <Layout {...props}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <>
            {element}
          </>
        </MuiPickersUtilsProvider>
    </Layout>
  )
}

export default PageWrap
