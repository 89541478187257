import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from './Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from './Typography'
import Avatar from './Avatar'
import { makeStyles } from "@material-ui/core"
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import { palette } from '../../theme'
import hexToRgbA from '../../utils/hexToRgbA'

const responsive_footer_height = 80


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            width: '100%',
            [theme.breakpoints.down("xs")]: {
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
            }
        },
        '& .MuiPaper-root.MuiDialog-paperScrollBody': {
            [theme.breakpoints.down("xs")]: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                minHeight: '90%'
            }
        },
        '& .MuiDialog-paperScrollBody': {
            [theme.breakpoints.down("xs")]: {
                verticalAlign: 'bottom',
                width: '100%',
                maxWidth: '100%',
            }
        },
        '&.dark': {
            '& .MuiPaper-root': {
                backgroundColor: palette.gray.dark
            },
            '& .MuiIconButton-root': {
                color: palette.gray.lighter
            }
        },
        '&.has-footer': {
            '&  .MuiPaper-root.MuiDialog-paperScrollBody': {
                [theme.breakpoints.down("xs")]: {
                    position: 'relative',
                    paddingBottom: theme.spacing(10)
                }
            }
        }
    },
    footer: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: theme.spacing(10),
            overflow: 'hidden',
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            paddingBottom: 0
        },
        '& > div': {
            [theme.breakpoints.down("xs")]: {
                width: '100%'
            }
        }
    },
    title: {
        textAlign: 'left',
        fontSize: '1.3em'
    },
    close: {
        position: 'absolute',
        top: '0.75em',
        right: '0.75em',
        [theme.breakpoints.down("xs")]: {
            right: '0.6em'
        }
    },
    header: {
        padding: `${ theme.spacing(3) }px ${ theme.spacing(7) }px ${ theme.spacing(3) }px ${ theme.spacing(4) }px`,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        minWidth: 360,
        '& > * ': {
            marginRight: '0.5em'
        },
        '& .MuiTypography-caption': {
            marginTop: '0.5em',
            marginRight: 0,
            width: '100%',
        }
    },
    avatar: {
        width: 40,
        height: 40,
        [theme.breakpoints.down("md")]: {
            width: 30,
            height: 30
        },
        [theme.breakpoints.down("xs")]: {
            width: 25,
            height: 25
        }
    },
    video: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing(4)
    },
    body: {
        padding: `0 ${ theme.spacing(4) }px ${ theme.spacing(3) }px ${ theme.spacing(4) }px`,
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(4)
        },
        '& video': {
            width: '100%',
            height: 'auto'
        }
    }
}))

function ModalComponent({ id, children, open, title, subtitle, handleClose, profiles, dark, videoSrc, footer, size = 'md', videoMime = "video/mp4", videoWidth = 1920, videoHeight = 1080, disabled = false }) {

    const classes = useStyles()
    let hasAction
    const buttonAttrs = {}
    if (typeof actionClick !== 'undefined') {
        hasAction = true
        buttonAttrs.onClick = (e) => {
            actionClick(e)
        }
    } else if (typeof actionTo !== 'undefined') {
        hasAction = true
        buttonAttrs.to = actionTo
    }

    const onClose = (e) => {
        if(disabled) e.preventDefault()
        if(!disabled && typeof handleClose !== 'undefined') handleClose()
    }

    const gradient = dark ? `linear-gradient(0deg, ${ hexToRgbA(palette.gray.dark, 1) } 70%, ${ hexToRgbA(palette.gray.dark, 0) } 100%)` : `linear-gradient(0deg, ${ hexToRgbA('#ffffff', 1) } 70%, ${ hexToRgbA('#ffffff', 0) } 100%)`

    return (
        <Dialog
            open={open}
            onClose={onClose}
            closeAfterTransition
            aria-labelledby={`${ id }-title`}
            aria-describedby={`${ id }-subtitle`}
            className={`${ classes.root } ${ dark ? 'dark' : '' } ${ typeof footer !== 'undefined' ? 'has-footer' : '' }`}
            BackdropComponent={Backdrop}
            scroll={'body'}
            maxWidth={size}
            BackdropProps={{
                timeout: 500,
            }}
            
        >
            <Fade in={open}>
                <div>
                    {typeof handleClose !== 'undefined' && (
                        <IconButton aria-label="delete" onClick={onClose} className={classes.close} disabled={disabled}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    )}
                    <div className={classes.header}>
                        <Typography variant="h1" color={dark ? 'light' : 'gray'} shade={dark ? 'light' : 'dark'} className={classes.title} id={`${ id }-title`}>{title}</Typography>
                        {Array.isArray(profiles) && profiles.length > 0 && (
                            profiles.map(profile => <Avatar tooltip className={classes.avatar} key={profile.id} profile={profile} />)
                        )}
                        {typeof subtitle !== 'undefined' && <Typography variant="caption" color={dark ? 'light' : 'gray'} align="left" id={`${ id }-subtitle`}>{subtitle}</Typography>}
                    </div>
                    {typeof videoSrc !== 'undefined' && (
                        <video width={videoWidth} height={videoHeight} className={classes.video} controls>
                            <source src={videoSrc} type={videoMime} />
                        </video>
                    )}
                    <div className={classes.body}>
                        {children}
                    </div>
                    {typeof footer !== 'undefined' && (
                        <footer className={classes.footer} style={{ background: gradient }}>
                            <div>
                                {footer}
                            </div>
                        </footer>
                    )}
                </div>
            </Fade>
        </Dialog>
    )
}

export default ModalComponent
