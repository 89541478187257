import React from 'react'
import { Typography, Card, Avatar, Icon } from '.'
import { IconButton, makeStyles } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Link } from 'gatsby'
import hexToRgbA from '../../utils/hexToRgbA'
import theme from '../../theme'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: theme.palette.light.light,
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
        '&.size-sm': {
            borderRadius: 8,
            padding: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(1),
            },
            '& > div': {
                '& > div.content': {
                    paddingLeft: theme.spacing(2),
                    '& ol': {
                        paddingLeft: theme.spacing(1)
                    },
                }
            }
        },
        '& .MuiIconButton-root': {
            backgroundColor: hexToRgbA(theme.palette.light.light, 0.13)
        },
        '& > div': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            '& > div.content': {
                textAlign: 'left',
                width: '100%',
                paddingLeft: theme.spacing(3),
                '& ol': {
                    paddingLeft: theme.spacing(2)
                },
                '& > *': {
                    margin: 0,
                    marginBottom: theme.spacing(1),
                    '&:last-child': {
                        marginBottom: 0
                    }
                }
            }
        },
        '&.valign-middle': {
            '& > div': {
                alignItems: 'center'
            }
        },
        '&.squared': {
            borderRadius: 0,
            padding: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(1),
            },
        }
    },
}))

function Alert({ title, children, icon, to, color, size = 'lg', alignMiddle, squared }) {
    const classes = useStyles()
    let gradient
    switch (color) {
        case 'secondary':
            gradient = `linear-gradient(45deg, ${ theme.palette.primary.main } 0%, ${ theme.palette.secondary.main } 100%)`
            break
        case 'green':
            gradient = `linear-gradient(45deg, ${ theme.palette.green.main } 0%, ${ theme.palette.green.light } 100%)`
            break
        case 'red':
            gradient = `linear-gradient(45deg, ${ theme.palette.red.main } 0%, ${ theme.palette.red.light } 100%)`
            break
        case 'orange':
            gradient = `linear-gradient(45deg, ${ theme.palette.orange.main } 0%, ${ theme.palette.orange.light } 100%)`
            break
        default:
            gradient = `linear-gradient(45deg, ${ theme.palette.primary.main } 0%, ${ theme.palette.pink.main } 100%)`
            break
    }

    return (
        <Card bg="purple" className={`${ classes.root } size-${ size } valign-${alignMiddle ? 'middle' : 'none'} ${squared && 'squared'}`} shadowOff gradient={gradient}>
            <div>
                {icon && <div className="icon">{icon}</div>}
                <div className="content">
                    {title && <Typography variant="caption" color="light" shade="light" uppercase><strong>{title}</strong></Typography>}
                    {children}
                </div>
                {to && (
                    <IconButton color="inherit" component={Link} to={to}>
                        <ChevronRightIcon />
                    </IconButton>
                )}
            </div>
        </Card>
    )
}

export default Alert
