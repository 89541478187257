import React from 'react'
import getLabelFromLevel from './getLabelFromLevel'

const getMoodOptions = (exclude, labels, icons) => {
    icons = icons || [
        'very-bad',
        'bad',
        'neutral',
        'good',
        'very-good',
    ]
    let colors = [
        'danger',
        'warning',
        'neutral',
        'good',
        'great',
    ]
    let values = [0,1,2,3,4]
    if(exclude){
        values = values.filter((v) => !exclude.includes(v))
    }
    let options = values.map((v, i) => ({
        value: i,
        label: <><i className={`icon-mood-${icons[i]} ${colors[v]}`} /><span>{getLabelFromLevel(i, labels)}</span></>, 
        color: colors[v]
    }))

    return options
}
export default getMoodOptions