import React from 'react'
import { makeStyles } from "@material-ui/core"
import { max } from 'moment'
import { Typography } from '.'
import getColorFromLevel from '../../utils/getColorFromLevel'

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    bars: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        '&.hide-last-num': {
            '& > div:last-child span': {
                display: 'none'
            }
        }
    },
    bar: {
        '& b ': {
            height: theme.spacing(3),
            display: 'block'
        },
        '& span': {
            display: 'block',
            textAlign: 'right'
        }
    },
    legend: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        '& i': {
            display: 'inline-block',
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: 4,
            marginRight: theme.spacing(2)
        }
    }
}))


function calcBarWidth(value, max_value){
    return `${Math.round((value * 100) / max_value)}%`
}

function ScoreScale({levels, hideLastNum, hideBar}) {
    const classes = useStyles()
    const valid_levels = Array.isArray(levels) && levels.length > 0
    const max_value = valid_levels ? [...levels].sort((a, b) => b.value - a.value)[0].value : 100
    let bars = []
    let prev_value = 0
    for (const lvl of levels) {
        let percent = Math.round(((lvl.value - prev_value) * 100) / max_value);
        bars.push(
            <div className={classes.bar} style={{width: `${percent}%`}} key={`score-scale-bar-${lvl.value}`}>
                <b style={{backgroundColor: getColorFromLevel(lvl.level)}}></b>
                <Typography variant="caption">{lvl.value}</Typography>
            </div>
        )
        prev_value = lvl.value
    }

    if(!valid_levels) return <></>

    return (
        <div className={classes.root}>
            {!hideBar && (
                <div className={`${classes.bars} ${hideLastNum ? 'hide-last-num' : ''}`}>
                    {bars}
                </div>
            )}
            {levels.map(lvl => (
                <div className={classes.legend} key={lvl.label}>
                    <i style={{backgroundColor: getColorFromLevel(lvl.level)}}></i>
                    <Typography variant="caption" color={getColorFromLevel(lvl.level)}><strong>{lvl.label}</strong></Typography>
                </div>
            ))}
        </div>
    )
}

export default ScoreScale
