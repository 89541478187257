import { animals } from '../components/library/Avatar'
import getPetLevelStatus from './getPetLevelStatus'
import getPetMaxLevel from './getPetMaxLevel'
import getPetLevels from './getPetLevels'

function getProfiles(user, petBones) {
    if(!user) return null
    const profiles = {}
    for (const role of ['child', 'parent', 'pet', 'petType']) {        
        let profile = {
            role,
            id: `profile-${role}`,
            avatar: role === 'parent' ? animals[0] : animals[1],
            name: ''
        }
        if(user?.attributes[`field_${role}_name`]){
            profile.name = user.attributes[`field_${role}_name`]
        }
        if(role !== 'pet'){
            if(user?.attributes[`field_${role}_avatar`]){
                profile.avatar = user.attributes[`field_${role}_avatar`]
            }
        }else if (role == 'pet') {
            const levels = getPetLevels()
            const max_level = getPetMaxLevel(levels)
            let lvl_status = getPetLevelStatus(levels, petBones, max_level)

            if(lvl_status.level <= 5) {
                profile.avatar = `pup${lvl_status.level}`
            } else {
                profile.avatar = `pup5`
            }
        }
        profiles[role] = profile
    }
    return profiles
}

export default getProfiles