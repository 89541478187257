import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from "@material-ui/core"
import { Typography } from './'
import hexToRgbA from '../../utils/hexToRgbA'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'inline-flex',
        width: `${ theme.spacing(10) }px !important`,
        height: `${ theme.spacing(10) }px !important`,
        borderRadius: '50%',
        [theme.breakpoints.down("md")]: {
            width: `${ theme.spacing(8) }px !important`,
            height: `${ theme.spacing(8) }px !important`,
        },
        '&.color-light': {
            backgroundColor: hexToRgbA('#ffffff', 0.15),
            '& .MuiCircularProgress-colorPrimary svg .MuiCircularProgress-circle': {
                stroke: hexToRgbA('#ffffff', 0.15)
            },
            '& .MuiCircularProgress-colorSecondary svg .MuiCircularProgress-circle': {
                stroke: '#ffffff'
            },
            '& .MuiTypography-caption': {
                color: '#ffffff !important'
            }
        }
    },
    circle: {
        width: `${ theme.spacing(10) }px !important`,
        height: `${ theme.spacing(10) }px !important`,
        [theme.breakpoints.down("md")]: {
            width: `${ theme.spacing(8) }px !important`,
            height: `${ theme.spacing(8) }px !important`,
        },
        '&:nth-child(2)': {
            '& .MuiCircularProgress-circle': {
                stroke: hexToRgbA('#ffffff', 0.65)
            }
        },
        '&:nth-child(3)': {
            position: 'absolute',
            top: 0,
            left: 0,
            '& .MuiCircularProgress-circle': {
                stroke: theme.palette.green.light
            }
        },
    },
    label: {
        fontSize: 13,
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.down("md")]: {
            fontSize: 12
        },
    },
    box: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

function Progress({ step, total, color = 'default' }) {
    const classes = useStyles()
    const value = Math.ceil((step * 100) / total)
    return (
        <div className={`${ classes.root } color-${ color }`}>
            <div className={classes.box}>
                <Typography variant="caption" className={classes.label}>{`${ step } of ${ total }`}</Typography>
            </div>
            <CircularProgress variant="determinate" value={100} className={classes.circle} color="primary" />
            <CircularProgress variant="determinate" value={value} className={classes.circle} color="secondary" />
        </div>
    )
}

export default Progress
