import {DateTime, Interval} from 'luxon'
import getWeekGoals from './getWeekGoals'
import getWeekCheckIns from './getWeekCheckIns'
import getSpirometries from './getSpirometries'
import getCACTS from './getCACTS'

const calcWeeks = (user, userData, today) => {
    let user_start_date_iso = user?.attributes?.field_start_date_iso ? user.attributes.field_start_date_iso : null
    if (user_start_date_iso) {
        const user_start_date = DateTime.fromISO(user_start_date_iso).set({hours: 0, minutes: 0, seconds: 0})
        const finish_date = user_start_date.plus({weeks: 8})
        const cacts = getCACTS(userData)
        const spiros = getSpirometries(userData)
        let weeks = []
        // const diff = today.set({hours: 23, minutes: 59, seconds: 59}).diff(user_start_date, 'days')
        const diff = finish_date.diff(user_start_date, 'days')
        const weeks_count = Math.ceil(diff.days / 7)
        for (let number = 0; number < weeks_count; number++) {
            const start_date = user_start_date.plus({days: (7 * number)}).plus({seconds: 1})
            const end_date = start_date.plus({days: 7}).plus({seconds: -2})
            const interval = Interval.fromDateTimes(start_date, end_date)
            let label = start_date.toFormat('LLL d - ')
            if(start_date.month !== end_date.month) label += ` ${end_date.toFormat('LLL')} `
            label += end_date.toFormat('d, y')
            const goals = getWeekGoals(userData, number);
            const goals_checked_in = goals.filter(uuid => {
                const goal = userData[uuid]
                if(goal?.attributes){
                  return typeof goal.attributes.field_check_in_value !== 'undefined' && goal.attributes.field_check_in_value !== null
                }
                return false
            })
            const goals_not_checked_in = goals.filter(uuid => {
                const goal = userData[uuid]
                if(goal?.attributes){
                  return typeof goal.attributes.field_check_in_value === 'undefined' || goal.attributes.field_check_in_value === null
                }
                return false
            })
            const goals_are_all_checked_in = goals.length === goals_checked_in.length
            const goals_daily_check_in_goal = goals.find(uuid => {
                const goal = userData[uuid]
                return goal.type === 'node--asthma_check_in_goal' && typeof goal.attributes !== 'undefined'
            })
            const goals_daily_check_in_goal_exists = typeof goals_daily_check_in_goal !== 'undefined'
            const goals_daily_check_in_goal_frequency = goals_daily_check_in_goal_exists ? userData[goals_daily_check_in_goal].attributes.field_check_in_frequency : null
            const cact = cacts.find(ca => {
                if(ca?.attributes?.field_test_week){
                    return `${ca?.attributes?.field_test_week}` === `week${number}`
                }
                return false
            })
            const spiro = spiros.find(sp => {
                if(sp?.attributes?.field_test_week){
                    return `${sp?.attributes?.field_test_week}` === `week${number}`
                }
                return false
            })
            
            const cact_done = typeof cact !== 'undefined'
            const spiro_done = typeof spiro !== 'undefined'
            const todo_done = goals_are_all_checked_in && cact_done && spiro_done
            let todo_total = goals.length + 2
            let todo_progress = todo_done ? todo_total : goals_checked_in.length
            if(!todo_done){
                if(cact_done) todo_progress++
                if(spiro_done) todo_progress++
            }
            const todo_left = todo_total - todo_progress
            const check_ins = getWeekCheckIns(userData, interval)
            let check_ins_left = goals_daily_check_in_goal_frequency !== null ? goals_daily_check_in_goal_frequency - check_ins.length : null
            if(check_ins_left < 0) check_ins_left = 0
            // console.log('A WEEK', number);
            // console.log('start_da', start_date.toISO());
            // console.log('end_date', end_date.toISO());
            weeks.push({
                number,
                start_date,
                start_date_iso: start_date.toISO(),
                end_date,
                end_date_iso: end_date.toISO(),
                interval,
                label,
                check_ins,
                check_ins_left,
                goals,
                goals_checked_in,
                goals_not_checked_in,
                goals_are_all_checked_in,
                goals_daily_check_in_goal,
                goals_daily_check_in_goal_exists,
                goals_daily_check_in_goal_frequency,
                todo_done,
                todo_progress,
                todo_total,
                todo_left,
                cact,
                cact_done,
                spiro,
                spiro_done,
            })   
        }
        return weeks
    }
    return null
}

export default calcWeeks
