import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles"
import { palette } from '../../theme'

const Custom = withStyles({
    root: {
        color: props => props.hex,
        textAlign: props => props.align ? props.align : null,
        textTransform: props => props.uppercase === 'uppercase' ? 'uppercase' : 'none',
        display: props => props.inline === 'inline' ? 'inline' : 'block'
    }
})(Typography)

function TypographyComponent({ className, id, children, align, uppercase, variant = 'body1', color = 'gray', shade = 'main', component, inline }) {
    const hex = palette[color] ? palette[color][shade] : color
    const attrs = {
        hex,
        align,
        uppercase: uppercase === true ? 'uppercase' : 'none',
        inline: inline === true ? 'inline' : 'none',
    }
    if (className) attrs.className = className
    if (id) attrs.id = id
    if (component) attrs.component = component
    return (
        <Custom {...attrs} variant={variant}>{children}</Custom>
    )
}

export default TypographyComponent