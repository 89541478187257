import React from 'react'
import { Image, Typography } from '.'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 199,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down("md")]: {
            maxWidth: 377,
        },

        [theme.breakpoints.down("xs")]: {
            maxWidth: 319,
        }
    },
    slogan: {
        fontSize: '1.2em',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            fontSize: '1em',
        },
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(1),
            fontSize: '1em',
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: '0.9em',
        }
    }
}))

function Logo() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Image src="/logo.png" aspectRatio={2.65} />
            <Typography className={classes.slogan} align="center">Improving Asthma Care Together</Typography>
        </div>
    )
}

export default Logo
