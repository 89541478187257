// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-child-tsx": () => import("./../../../src/pages/account/child.tsx" /* webpackChunkName: "component---src-pages-account-child-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-parent-tsx": () => import("./../../../src/pages/account/parent.tsx" /* webpackChunkName: "component---src-pages-account-parent-tsx" */),
  "component---src-pages-account-pet-tsx": () => import("./../../../src/pages/account/pet.tsx" /* webpackChunkName: "component---src-pages-account-pet-tsx" */),
  "component---src-pages-account-pet-type-tsx": () => import("./../../../src/pages/account/petType.tsx" /* webpackChunkName: "component---src-pages-account-pet-type-tsx" */),
  "component---src-pages-asthma-check-in-detail-view-tsx": () => import("./../../../src/pages/asthma-check-in-detail-view.tsx" /* webpackChunkName: "component---src-pages-asthma-check-in-detail-view-tsx" */),
  "component---src-pages-asthma-check-in-form-tsx": () => import("./../../../src/pages/asthma-check-in-form.tsx" /* webpackChunkName: "component---src-pages-asthma-check-in-form-tsx" */),
  "component---src-pages-c-act-detail-view-tsx": () => import("./../../../src/pages/c-act-detail-view.tsx" /* webpackChunkName: "component---src-pages-c-act-detail-view-tsx" */),
  "component---src-pages-c-act-form-tsx": () => import("./../../../src/pages/c-act-form.tsx" /* webpackChunkName: "component---src-pages-c-act-form-tsx" */),
  "component---src-pages-components-actions-js": () => import("./../../../src/pages/components/actions.js" /* webpackChunkName: "component---src-pages-components-actions-js" */),
  "component---src-pages-components-alert-js": () => import("./../../../src/pages/components/alert.js" /* webpackChunkName: "component---src-pages-components-alert-js" */),
  "component---src-pages-components-back-button-js": () => import("./../../../src/pages/components/back-button.js" /* webpackChunkName: "component---src-pages-components-back-button-js" */),
  "component---src-pages-components-button-js": () => import("./../../../src/pages/components/button.js" /* webpackChunkName: "component---src-pages-components-button-js" */),
  "component---src-pages-components-card-actions-js": () => import("./../../../src/pages/components/card-actions.js" /* webpackChunkName: "component---src-pages-components-card-actions-js" */),
  "component---src-pages-components-card-collapse-js": () => import("./../../../src/pages/components/card-collapse.js" /* webpackChunkName: "component---src-pages-components-card-collapse-js" */),
  "component---src-pages-components-card-info-js": () => import("./../../../src/pages/components/card-info.js" /* webpackChunkName: "component---src-pages-components-card-info-js" */),
  "component---src-pages-components-card-js": () => import("./../../../src/pages/components/card.js" /* webpackChunkName: "component---src-pages-components-card-js" */),
  "component---src-pages-components-card-person-js": () => import("./../../../src/pages/components/card-person.js" /* webpackChunkName: "component---src-pages-components-card-person-js" */),
  "component---src-pages-components-card-turn-js": () => import("./../../../src/pages/components/card-turn.js" /* webpackChunkName: "component---src-pages-components-card-turn-js" */),
  "component---src-pages-components-dialog-js": () => import("./../../../src/pages/components/dialog.js" /* webpackChunkName: "component---src-pages-components-dialog-js" */),
  "component---src-pages-components-image-js": () => import("./../../../src/pages/components/image.js" /* webpackChunkName: "component---src-pages-components-image-js" */),
  "component---src-pages-components-index-js": () => import("./../../../src/pages/components/index.js" /* webpackChunkName: "component---src-pages-components-index-js" */),
  "component---src-pages-components-input-answer-js": () => import("./../../../src/pages/components/input-answer.js" /* webpackChunkName: "component---src-pages-components-input-answer-js" */),
  "component---src-pages-components-input-avatar-js": () => import("./../../../src/pages/components/input-avatar.js" /* webpackChunkName: "component---src-pages-components-input-avatar-js" */),
  "component---src-pages-components-input-checkbox-js": () => import("./../../../src/pages/components/input-checkbox.js" /* webpackChunkName: "component---src-pages-components-input-checkbox-js" */),
  "component---src-pages-components-input-frequency-js": () => import("./../../../src/pages/components/input-frequency.js" /* webpackChunkName: "component---src-pages-components-input-frequency-js" */),
  "component---src-pages-components-input-mood-js": () => import("./../../../src/pages/components/input-mood.js" /* webpackChunkName: "component---src-pages-components-input-mood-js" */),
  "component---src-pages-components-input-notifications-js": () => import("./../../../src/pages/components/input-notifications.js" /* webpackChunkName: "component---src-pages-components-input-notifications-js" */),
  "component---src-pages-components-input-radio-js": () => import("./../../../src/pages/components/input-radio.js" /* webpackChunkName: "component---src-pages-components-input-radio-js" */),
  "component---src-pages-components-input-select-date-js": () => import("./../../../src/pages/components/input-select-date.js" /* webpackChunkName: "component---src-pages-components-input-select-date-js" */),
  "component---src-pages-components-input-select-js": () => import("./../../../src/pages/components/input-select.js" /* webpackChunkName: "component---src-pages-components-input-select-js" */),
  "component---src-pages-components-input-select-time-js": () => import("./../../../src/pages/components/input-select-time.js" /* webpackChunkName: "component---src-pages-components-input-select-time-js" */),
  "component---src-pages-components-input-slider-js": () => import("./../../../src/pages/components/input-slider.js" /* webpackChunkName: "component---src-pages-components-input-slider-js" */),
  "component---src-pages-components-input-slider-mood-js": () => import("./../../../src/pages/components/input-slider-mood.js" /* webpackChunkName: "component---src-pages-components-input-slider-mood-js" */),
  "component---src-pages-components-input-text-js": () => import("./../../../src/pages/components/input-text.js" /* webpackChunkName: "component---src-pages-components-input-text-js" */),
  "component---src-pages-components-input-textarea-js": () => import("./../../../src/pages/components/input-textarea.js" /* webpackChunkName: "component---src-pages-components-input-textarea-js" */),
  "component---src-pages-components-library-wrapper-js": () => import("./../../../src/pages/components/LibraryWrapper.js" /* webpackChunkName: "component---src-pages-components-library-wrapper-js" */),
  "component---src-pages-components-logo-js": () => import("./../../../src/pages/components/logo.js" /* webpackChunkName: "component---src-pages-components-logo-js" */),
  "component---src-pages-components-modal-js": () => import("./../../../src/pages/components/modal.js" /* webpackChunkName: "component---src-pages-components-modal-js" */),
  "component---src-pages-components-pet-js": () => import("./../../../src/pages/components/pet.js" /* webpackChunkName: "component---src-pages-components-pet-js" */),
  "component---src-pages-components-progress-js": () => import("./../../../src/pages/components/progress.js" /* webpackChunkName: "component---src-pages-components-progress-js" */),
  "component---src-pages-components-report-archive-js": () => import("./../../../src/pages/components/report-archive.js" /* webpackChunkName: "component---src-pages-components-report-archive-js" */),
  "component---src-pages-components-report-cards-js": () => import("./../../../src/pages/components/report-cards.js" /* webpackChunkName: "component---src-pages-components-report-cards-js" */),
  "component---src-pages-components-report-chart-js": () => import("./../../../src/pages/components/report-chart.js" /* webpackChunkName: "component---src-pages-components-report-chart-js" */),
  "component---src-pages-components-report-glance-js": () => import("./../../../src/pages/components/report-glance.js" /* webpackChunkName: "component---src-pages-components-report-glance-js" */),
  "component---src-pages-components-report-quiz-js": () => import("./../../../src/pages/components/report-quiz.js" /* webpackChunkName: "component---src-pages-components-report-quiz-js" */),
  "component---src-pages-components-report-survey-js": () => import("./../../../src/pages/components/report-survey.js" /* webpackChunkName: "component---src-pages-components-report-survey-js" */),
  "component---src-pages-components-report-table-js": () => import("./../../../src/pages/components/report-table.js" /* webpackChunkName: "component---src-pages-components-report-table-js" */),
  "component---src-pages-components-report-timeline-js": () => import("./../../../src/pages/components/report-timeline.js" /* webpackChunkName: "component---src-pages-components-report-timeline-js" */),
  "component---src-pages-components-rocket-timer-js": () => import("./../../../src/pages/components/rocket-timer.js" /* webpackChunkName: "component---src-pages-components-rocket-timer-js" */),
  "component---src-pages-components-section-js": () => import("./../../../src/pages/components/section.js" /* webpackChunkName: "component---src-pages-components-section-js" */),
  "component---src-pages-components-slideshow-js": () => import("./../../../src/pages/components/slideshow.js" /* webpackChunkName: "component---src-pages-components-slideshow-js" */),
  "component---src-pages-components-steps-js": () => import("./../../../src/pages/components/steps.js" /* webpackChunkName: "component---src-pages-components-steps-js" */),
  "component---src-pages-components-tabs-js": () => import("./../../../src/pages/components/tabs.js" /* webpackChunkName: "component---src-pages-components-tabs-js" */),
  "component---src-pages-components-typography-js": () => import("./../../../src/pages/components/typography.js" /* webpackChunkName: "component---src-pages-components-typography-js" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-goal-check-in-tsx": () => import("./../../../src/pages/goal-check-in.tsx" /* webpackChunkName: "component---src-pages-goal-check-in-tsx" */),
  "component---src-pages-goal-form-tsx": () => import("./../../../src/pages/goal-form.tsx" /* webpackChunkName: "component---src-pages-goal-form-tsx" */),
  "component---src-pages-goals-tsx": () => import("./../../../src/pages/goals.tsx" /* webpackChunkName: "component---src-pages-goals-tsx" */),
  "component---src-pages-import-symptoms-tsx": () => import("./../../../src/pages/import-symptoms.tsx" /* webpackChunkName: "component---src-pages-import-symptoms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-pair-wearable-tsx": () => import("./../../../src/pages/pair-wearable.tsx" /* webpackChunkName: "component---src-pages-pair-wearable-tsx" */),
  "component---src-pages-reports-c-act-tsx": () => import("./../../../src/pages/reports/c-act.tsx" /* webpackChunkName: "component---src-pages-reports-c-act-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-reports-spirometer-tsx": () => import("./../../../src/pages/reports/spirometer.tsx" /* webpackChunkName: "component---src-pages-reports-spirometer-tsx" */),
  "component---src-pages-spirometry-detail-view-tsx": () => import("./../../../src/pages/spirometry-detail-view.tsx" /* webpackChunkName: "component---src-pages-spirometry-detail-view-tsx" */),
  "component---src-pages-spirometry-test-form-tsx": () => import("./../../../src/pages/spirometry-test-form.tsx" /* webpackChunkName: "component---src-pages-spirometry-test-form-tsx" */),
  "component---src-pages-weekly-to-do-tsx": () => import("./../../../src/pages/weekly-to-do.tsx" /* webpackChunkName: "component---src-pages-weekly-to-do-tsx" */)
}

