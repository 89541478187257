import React from 'react'
import { makeStyles } from "@material-ui/core"
import { Button, Typography } from '.'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        '& .MuiTypography-h1': {
            fontSize: '1.3em'
        },
    }
}))

function Subtitle({ title, shareUrl }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography variant="h1" shade="dark" align="left">{title}</Typography>
            {shareUrl && <Button href={shareUrl} size="small" variant="outlined" color="primary" inline align="right">Share</Button>}
        </div>
    )
}

export default Subtitle
