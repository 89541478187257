import React, {useState, useEffect, useContext} from 'react'
import { ButtonGroup, makeStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import { Chart } from "react-google-charts";
import {Card, ReportGlance, Icon, Button, CircularProgress} from './';
import getColorFromLevel from '../../utils/getColorFromLevel'
import getLabelFromLevel from '../../utils/getLabelFromLevel'
import getRandomInt from '../../utils/getRandomInt'
import getLevel from '../../utils/getLevel'
import theme from '../../theme'
import { ImpactContext } from '../Layout';

const useWrapperStyles = makeStyles((theme) => ({
    root: {
        '&.expanded': {
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(2),
            },
        }
    }
}))

const useStyles = makeStyles((theme) => ({
    glance: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    topbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 0, 3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 0, 2),
        },
    },
    tabs: {
        padding: theme.spacing(0.5),
        borderRadius: 4,
        backgroundColor: theme.palette.gray.lightest,
        '& .MuiButton-root': {
            borderRadius: 4,
            padding: theme.spacing(0.5, 2.5),
            minWidth: `60px !important`,
            color: theme.palette.gray.dark,
            '&.active': {
                background: 'white'
            },
            '&.inactive': {
                background: 'transparent !important'
            },
            '& + .MuiButton-root': {
                marginLeft: theme.spacing(0.5),
            }
        }
    },
    btnArrow: {
        paddingLeft: `0 !important`,
        paddingRight: `0 !important`
    },
    link: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            '& > div': {
                backgroundColor: `${ theme.palette.secondary.lightest } !important`,
                transform: 'scale(1.2)',
                '& i': {
                    color: `${ theme.palette.secondary.main } !important`,
                }
            }
        }
    },
    chart: {
        position: 'relative',
        paddingTop: '50%',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(2),
        },
        '&.size-md': {
            paddingTop: '42%',
        },
        '&.size-sm': {
            paddingTop: '35%',
        },
        '& > div': {
            position: 'absolute',
            top: 0,
            left: '-2.5%',
            width: '110%',
            height: '100%',
            [theme.breakpoints.down("sm")]: {
                left: '2.5%',
                width: '100%',
            },
            [theme.breakpoints.down("xs")]: {
                left: '5%',
                width: '95%',
            },
            '& > div': {
                width: '100%',
                height: '100%',
            }
        }
    }
}))

function defaultLevels(max, total_levels = 5){
    if(typeof max !== 'number') return []
    const block_size = parseFloat(max / total_levels)
    if(isNaN(block_size)) return []
    let levels = []
    for (let index = 0; index <= total_levels; index++) {
        levels.push({
            level: index,
            from: block_size * (index),
            to: block_size * (index + 1)
        })
    }
    return levels
}



function formatNumber(num, unit){
    if(num === null) return '-'
    switch (unit) {
        case 'percent':
            return `${Math.floor(num * 100)}%`
            break;
        case 'integer':
            return `${parseInt(num)}`
            break;
        default:
            return num;
    }
}

function readableDate(dt) {
    const two_days_milli = 1000 * 60 * 60 * 24 * 2
    const diff = DateTime.now() - dt
    if(diff > two_days_milli){
        return dt.toLocaleString(DateTime.DATETIME_MED)
    }else{
        return `${ dt.toRelativeCalendar() }, ${ dt.toLocaleString(DateTime.TIME_SIMPLE) }`
    }
}

function readableDateRange(dt1, dt2){
    return `${dt1.toLocaleString(DateTime.DATE_MED)} - ${dt2.toLocaleString(DateTime.DATE_MED)}`
}

function formatChartData(data, unit, chartLevels, mode, chartSelection, maxValue, disableLevelColors){
    const no_data = data.filter(itm => itm.value === null).length === data.length
    if(no_data) return null
    let cols = []
    cols.push(mode === 'month' ? 'Date' : 'Day')
    cols.push(unit === 'percent' ? '%' : '')
    cols.push({ role: 'style' })
    cols.push({ role: 'tooltip' })
    const chartData = [
        cols,
    ]
    const day_selected = chartSelection !== null ? data[chartSelection[0].row] : null
    for (const itm of data) {
        let item = []
        item.push(mode === 'month' ? itm.date.toFormat('d') : itm.date.toFormat('EEE') )
        item.push(itm.value)
        const day_level = disableLevelColors ? 0 : getLevel(itm.value, chartLevels, maxValue)
        let data_color = theme.palette.gray.lighter
        if(chartSelection === null || day_selected === itm){
            data_color = disableLevelColors ? theme.palette.primary.light : getColorFromLevel(day_level)
        }
        item.push(data_color)
        item.push(`${formatNumber(itm.value, unit)} - ${itm.date.toLocaleString(DateTime.DATE_MED)}`)
        chartData.push(item)
    }
    return chartData
}

function getPreviousDay(data, day){
    const current_index = data.indexOf(day)
    if(current_index <= 0) return null
    return data[current_index - 1]
}

function getGlanceDescription(value, level, chartLevels, glanceDescription){
    if(value === null) return ''
    let description = ''
    const level_config = typeof level === 'number' ? chartLevels.find((lvl) => level === lvl.level) : null
    const force_description = level_config && typeof level_config.label !== 'undefined' ? level_config.label : null
    if(force_description){
        description = force_description
    }else if(typeof glanceDescription !== 'undefined'){
        description = value === 1 ? `${glanceDescription.singular}` : `${glanceDescription.plural}`
    }else if(level){
        description = getLabelFromLevel(level)
    }
    return description
}

function getDayData(day, currentData, chartLevels, unit, mode, maxValue, glanceDescription, disableLevelColors, hideEmptyDays, allData, week){
    const day_data = {}
    day_data.number = formatNumber(day.value, unit)
    day_data.level = getLevel(day.value, chartLevels, maxValue)
    day_data.description = getGlanceDescription(day.value, day_data.level, chartLevels, glanceDescription)
    day_data.note = day.value !== null ? `Taken ${readableDate(day.date)}` : mode === 'week' ? `No data for week ${week.number + 1}` : 'No data'
    if(day.value !== null){
        const lvl_obj = getLevel(day.value, chartLevels, maxValue, true)
        if(lvl_obj && lvl_obj.note){
            day_data.note += ` - ${lvl_obj.note}`
        }
    }
    if(typeof day.uuid !== 'undefined'){
        day_data.toUUID = day.uuid
    }
    if(disableLevelColors){
        day_data.disableLevelColors = true
    }
    if(mode !== 'collapsed'){
        day_data.cardBg = disableLevelColors ? theme.palette.primary.lightest : getColorFromLevel(day_data.level, 'lightest')
        day_data.shadowOff = true
    }
    if(allData !== null && day.value !== null){
        let day_index = getDayIndex(allData, day.date.toFormat('yyyy LLL dd'))
        if(day_index > 0){
            let prev_days = allData.slice(0, day_index)
            let avg_number = calcAverage(prev_days, unit, hideEmptyDays)
            const diff = day.value - avg_number
            const abs_diff = Math.abs(diff)
            day_data.indicator = abs_diff ? formatNumber(abs_diff, unit) : 0
            day_data.indicatorDirection = diff === 0 ? 'none' : diff < 0 ? 'down' : 'up'
        }
    }
    return day_data
}

function calcAverage(data, unit, exclude_empty){
    let with_data = data.filter((d) => d.value !== null)
    let avg_total = data.length > 0 ? data.filter(d => d && typeof d.value === 'number').map((d) => d.value).reduce((a, b) => a + b, 0) : null
    let avg_number = avg_total !== null && avg_total > 0 ? avg_total / with_data.length : null
    if(unit === 'integer' && avg_number !== null){
        avg_number = parseInt(avg_number)
    }
    return avg_number
}

function getAverageData(data, chartLevels, unit, mode, maxValue, glanceDescription, disableLevelColors){
    const avg_data = {}
    let avg_number = calcAverage(data, unit)
    avg_data.number = formatNumber(avg_number, unit)
    avg_data.level = disableLevelColors ? 0 : getLevel(avg_number, chartLevels, maxValue)
    avg_data.description = getGlanceDescription(avg_number, avg_data.level, chartLevels, glanceDescription)
    if(disableLevelColors){
        avg_data.disableLevelColors = true
    }
    if(typeof avg_data.description !== 'undefined' && avg_number !== null){
        avg_data.description += `/day`
    }
    avg_data.note = avg_number === null ? '' : `On average `;
    avg_data.note += readableDateRange(data[0].date, data[(data.length - 1)].date);
    if(avg_number !== null){
        const lvl_obj = getLevel(avg_number, chartLevels, maxValue, true)
        if(lvl_obj && lvl_obj.note){
            avg_data.note += ` - ${lvl_obj.note}`
        }
    }
    avg_data.cardBg = disableLevelColors || avg_number === null ? theme.palette.primary.lightest : getColorFromLevel(avg_data.level, 'lightest')
    avg_data.shadowOff = true
    return avg_data
}

function getDayIndex(data, day_str, force_index_with_data){
    let index = -1
    let indexes = []
    let indexes_with_data = []
    for (const day_index in data) {
       if(data[day_index].value !== null){
            indexes_with_data.push(day_index)
       }
       if(day_str === data[day_index].date.toFormat('yyyy LLL dd')){
        indexes.push(day_index)
       }
    }
    if(indexes.length > 0) index = indexes[indexes.length - 1]
    if(indexes_with_data.length > 0 && (index === -1 || (force_index_with_data && typeof data[index] !== 'undefined' && data[index].value === null) )) {
        index = indexes_with_data[indexes_with_data.length - 1]
    }
    return parseInt(index)
}

function getDayItem(data, day_str, hideEmptyDays){
    if(data.length === 0) return null
    // let with_data = hideEmptyDays ? data.filter((d) => d.value !== null) : data
    let with_data = data.filter((d) => d.value !== null)
    let today_index = with_data.length > 0 ? getDayIndex(with_data, day_str) : -1
    return today_index >= 0 ? with_data[today_index] : data[0]
}


function getWeekData(data, hideEmptyDays, week, relativeDate){
    let week_days = []
    if(relativeDate){
        for (let index = 0; index < 7; index++) {
            const a_week_day = DateTime.fromISO(relativeDate.plus({days: index}).toISO())
            week_days.push(a_week_day)
        }
    }else{
        for (let index = 0; index < 7; index++) {
            const a_week_day = DateTime.fromISO(week.start_date.plus({days: index}).toISO())
            week_days.push(a_week_day)
        }
    }
    let week_data = week_days.map((week_day_dt) => {
        let itm = {
            value: null,
            date: week_day_dt
        }
        let found_day = data.find((a_day) => a_day.date.toFormat('yyyy LLL dd') === week_day_dt.toFormat('yyyy LLL dd'))
        if(found_day){
            itm.date = found_day.date
            itm.value = found_day.value
            itm.uuid = found_day.uuid
        }
        return itm
    })
    
    return hideEmptyDays ?  week_data.filter((d) => d.value !== null) : week_data
}

function getMonthData(data, hideEmptyDays){
    let first_day = data[0].date
    let last_day = data[(data.length - 1)].date
    let diff = last_day.diff(first_day, 'days')
    let month_days = []
    for (let index = 0; index <= Math.round(diff.days); index++) {
        const a_month_day = DateTime.fromISO(first_day.plus({days: index}).toISO())
        month_days.push(a_month_day)
    }
    let month_data = month_days.map((month_day_dt) => {
        let itm = {
            value: null,
            date: month_day_dt
        }
        let found_day = data.find((a_day) => a_day.date.toFormat('yyyy LLL dd') === month_day_dt.toFormat('yyyy LLL dd'))
        if(found_day){
            itm.value = found_day.value
            itm.uuid = found_day.uuid
        }
        return itm
    })
    return hideEmptyDays ?  month_data.filter((d) => d.value !== null) : month_data
}

function getChartSize(data, type){
    let size = 'lg'
    if(type === 'BarChart' && data !== null){
        if(data.length < 3){
            size = 'sm'
        }else if(data.length === 3){
            size = 'lg'
        }
    }
    return size
}

function Wrapper({expanded, children}){
    const classes = useWrapperStyles()
    if(!expanded) return <div className={classes.root}>{children}</div>
    else return  <Card className={`${classes.root} expanded`} hideOverflow>{children}</Card>
}


function ReportChart({
    id,
    icon,
    icon_color = 'purple',
    data,
    chartType = 'ColumnChart',
    title,
    help,
    helpModal,
    unit,
    max,
    levels,
    to,
    toOverview,
    defaultMode = 'collapsed',
    enableInteractivity,
    glanceDescription,
    disableLevelColors,
    hideEmptyDays,
    hideTopbar,
    disableInteractivity,
    relativeDate
}) {

    const { currentWeek, today, weeks } = useContext(ImpactContext)
    const [focusWeek, setFocusWeek] = useState(currentWeek)
    const [weekData, setWeekData] = useState([])
    const [dayItem, setDayItem] = useState(null)
        
    const classes = useStyles()
    const icon_colors = {
        fg: theme.palette[icon_color].light,
        bg: theme.palette[icon_color].lighter,
    }

    const today_str = today.toFormat('yyyy LLL dd')
    const dataIsValid = Array.isArray(data) && data.length > 0
    const sortedData = dataIsValid ? [...data].sort((a, b) => a.date.ts - b.date.ts) : null
    const MonthData = dataIsValid ? getMonthData(sortedData, hideEmptyDays) : null
    
    // const weekData = dataIsValid && focusWeek !== null ? getWeekData(sortedData, hideEmptyDays, focusWeek, relativeDate) : []
    // let dayItem = dataIsValid ? getDayItem(weekData, today_str, hideEmptyDays) : null
    // if(dayItem !== null && dayItem.value === null) dayItem = null

    const maxValue = typeof max !== 'undefined' ? max : dataIsValid ? [...sortedData].sort((a, b) => b.value - a.value)[0].value : 0
    const chartLevels = typeof levels !== 'undefined' ? levels : defaultLevels(maxValue)
    
    const [mode, setMode] = useState(defaultMode) //collapsed, week, month
    const [currentData, setCurrentData] = useState(null)
    const [glanceAttrs, setGlanceAttrs] = useState(null)
    const [chartData, setChartData] = useState(null)
    const [chartSelection, setChartSelection] = useState(null)
    
    const chart_size = getChartSize(currentData, chartType)

    const valuesAxis = {
        minValue: 0,
        maxValue: maxValue,
        // maxValue: 1,
        format: unit === 'percent' ? 'percent' : null,
        // format: null,
        gridlines: {
            count: 4
        },
        textStyle: {
            fontSize: 11
        }
    }

    const datesAxis = {
        textStyle: {
            fontSize: 11
        }
    }

    useEffect(() => {
        setWeekData(dataIsValid && focusWeek !== null ? getWeekData(sortedData, hideEmptyDays, focusWeek, relativeDate) : [])
    }, [focusWeek])

    useEffect(() => {        
        let day_item = dataIsValid ? getDayItem(weekData, today_str, hideEmptyDays) : null
        if(day_item !== null && day_item.value === null) day_item = null
        setDayItem(day_item)
    }, [weekData])
    
    useEffect(() => {
        switch (mode) {
            case 'collapsed':
                setCurrentData(dayItem !== null ? [dayItem] : [])
                break;
            case 'week':
                setCurrentData(weekData)
                break;
            default:
                setCurrentData(MonthData)
                break;
        }
        if(mode === 'week'){
            const day_index = getDayIndex(weekData, today_str, true)
            if(day_index >= 0){
                setChartSelection([{row: day_index, column: 1}])
            }else{
                setChartSelection(null)
            }
        }else{
            setChartSelection(null)
        }
    }, [mode, dayItem])

    useEffect(() => {
        if(currentData !== null){
            if(mode !== 'collapsed'){
                setChartData(formatChartData(currentData, unit, chartLevels, mode, chartSelection, maxValue, disableLevelColors))
            }
            setGlanceAttrs(getGlanceData())
        }
    }, [currentData, chartSelection, mode])

    function moveWeekFocus(direction){
        const curr_index = focusWeek.number
        let next_index = direction === 'prev' ? curr_index - 1 : curr_index + 1
        if(next_index > currentWeek.number) next_index = 0
        if(next_index < 0) next_index = currentWeek.number
        let focus = weeks.find(w => w.number === next_index)
        if(!focus) focus = currentWeek
        setFocusWeek(focus)
    }


    function getGlanceData(){
        const isChartSelected = chartSelection && chartSelection.length === 1
        let glance = {}
        let current_day = null
        if(mode === 'collapsed'){
            current_day = dayItem
            if(typeof title !== 'undefined') glance.title = title;
            if(typeof help !== 'undefined') glance.help = help;
            if(typeof helpModal !== 'undefined') glance.helpModal = helpModal;
            if(typeof icon !== 'undefined') {
                glance.icon = icon;
                glance.icon_color = icon_color;
            }
            // if(dataIsValid) glance.onClick = (e) => {setMode('week')}
            glance.onClick = (e) => {setMode('week')}
        }
        if(mode !== 'collapsed'){
            if(chartSelection === null && typeof toOverview !== 'undefined'){
                glance.to = toOverview
            }
            if(typeof glance.to === 'undefined' && typeof to !== 'undefined'){
                glance.to = to
            }
            if(typeof glance.to !== 'undefined'){
                glance.actionIconBg = 'white'
                glance.actionIconFg = theme.palette.gray.main
            }
            if(isChartSelected && currentData !== null){
                current_day = currentData[chartSelection[0].row]
            }else{
                current_day = dayItem
            }            
        }
        if(dataIsValid && currentData !== null){
            if(current_day && (isChartSelected || mode === 'collapsed')){
                const day_data = getDayData(current_day, currentData, chartLevels, unit, mode, maxValue, glanceDescription, disableLevelColors, hideEmptyDays, sortedData, focusWeek)
                glance = {...glance, ...day_data}
            }else{
                const average_data = currentData.length > 0 ? getAverageData(currentData, chartLevels, unit, mode, maxValue, glanceDescription, disableLevelColors) : null
                if(average_data){
                    glance = {...glance, ...average_data}
                }else{
                    glance.number = '-'
                    glance.note = mode === 'month' ? 'No data!' : `No data for week ${focusWeek.number + 1}`
                }
            }
        }else{
            glance.number = '-'
            glance.note = mode === 'month' ? 'No data!!' : `No data for week ${focusWeek.number + 1}`
            
        }

        if(glance.number === '-'){
            glance.cardBg = 'white'
            glance.shadowOff = false
            delete glance.to
        }

        return glance
    }
    
    if(mode === null) return <CircularProgress />

    return (
        <Wrapper expanded={mode !== 'collapsed'}>
            {mode !== 'collapsed' && !hideTopbar && (
                <div className={classes.topbar}>
                    {icon && <Icon name={icon} size="sm" {...icon_colors} className={classes.icon} />}
                    <div className={classes.tabs}>
                        <Button inline size="small" variant="contained" disabled={mode !== 'week'} className={`${mode === 'week' ? 'active' : 'inactive'} ${classes.btnArrow}`} color="default" onClick={(e) => moveWeekFocus('prev')}><i className="icon-angle-left" size="sm" /></Button>
                        <Button inline size="small" variant="contained" className={`${mode === 'week' ? 'active' : 'inactive'}`} color="default" onClick={(e) => {
                            if(mode === 'week'){
                                moveWeekFocus('next')
                            }else{
                                setMode('week')
                            }
                        }}>{`Week ${focusWeek.number + 1}`}</Button>
                        <Button inline size="small" variant="contained" disabled={mode !== 'week'} className={`${mode === 'week' ? 'active' : 'inactive'} ${classes.btnArrow}`} color="default" onClick={(e) => moveWeekFocus('next')}><i className="icon-angle-right" size="sm" /></Button>
                        <Button inline size="small" variant="contained" className={`${mode === 'month' ? 'active' : 'inactive'}`} color="default" onClick={(e) => setMode('month')}>All Time</Button>
                    </div>
                    <button onClick={(e) => setMode('collapsed')} className={classes.link}><Icon name="stats" fg="white" bg={theme.palette.purple.main} /></button>
                </div>
            )}
            {glanceAttrs !== null && (
                <ReportGlance
                    {...glanceAttrs}
                />
            )}
            {chartData !== null && mode !== 'collapsed' && (
                <div className={`${classes.chart} size-${chart_size}`}>
                    {chartType !== 'LineChart' && (
                        <Chart
                            loader={<CircularProgress />}
                            data={chartData}
                            chartType={chartType}
                            options={{
                                chartArea: { 
                                    width: '85%', 
                                    height: '85%', 
                                    backgroundColor: 'transparent' 
                                },
                                enableInteractivity: disableInteractivity !== true,
                                legend: { position: 'none' },
                                backgroundColor: {
                                    fill: 'transparent'
                                },
                                vAxis: chartType === 'ColumnChart' ? valuesAxis : datesAxis,
                                hAxis: chartType === 'ColumnChart' ? datesAxis : valuesAxis
                            }}
                            chartEvents={[
                                {
                                    eventName: 'ready',
                                    callback: ({chartWrapper}) => {
                                        const chart = chartWrapper.getChart()
                                        if(chartSelection && chartSelection.length === 1 && disableInteractivity !== true){
                                            chart.setSelection(chartSelection)
                                        }
                                    }
                                },
                                {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => {
                                        const chart = chartWrapper.getChart()
                                        const selection = chart.getSelection()
                                        if (selection.length === 1) {
                                            const [selectedItem] = selection
                                            setChartSelection(selection)
                                        }else{
                                            setChartSelection(null)
                                        }
                                    }
                                }
                            ]}
                        />
                    )}
                </div>
            )}

        </Wrapper>
    )
}

export default ReportChart
