import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from './Button';
import Typography from './Typography';
// import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'gatsby';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center'
    },
    steps: {
        width: '25%',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '50%',
        },
        '& .MuiBreadcrumbs-ol': {
            justifyContent: 'center'
        }
    }
}));

function Actions({ dismissLabel = 'Cancel', submitLabel = 'Submit', dismissOnClick, submitOnClick, dismissTo, submitTo, dismissDisabled = false, submitDisabled = false, currentStep, totalSteps, submitButton, lastStep }) {
    const classes = useStyles();

    const dismissAttrs = {}
    const submitAttrs = {}

    if (typeof dismissTo !== 'undefined') {
        dismissAttrs.component = Link;
        dismissAttrs.to = dismissTo;
    } else if (typeof dismissOnClick !== 'undefined') {
        dismissAttrs.component = 'button';
        dismissAttrs.onClick = dismissOnClick;
    }
    dismissAttrs.disabled = dismissDisabled;

    if (typeof submitTo !== 'undefined') {
        submitAttrs.component = Link;
        submitAttrs.to = submitTo;
    } else if (typeof submitOnClick !== 'undefined') {
        submitAttrs.component = 'button';
        submitAttrs.onClick = submitOnClick;
    }
    submitAttrs.disabled = submitDisabled;

    let steps

    return (
        <div className={classes.root}>
            {dismissAttrs.component && <Button variant="contained" size="medium" {...dismissAttrs}>
                {dismissLabel}
            </Button>}
            {!dismissAttrs.component && <Button invisible />}
            {typeof currentStep !== 'undefined' && typeof totalSteps !== 'undefined' && <Breadcrumbs aria-label="breadcrumb" className={classes.steps}>
                <Typography>{currentStep}</Typography>
                <Typography>{totalSteps}</Typography>
            </Breadcrumbs>}
            {typeof submitButton !== 'undefined' && lastStep ? (
                submitButton
            ) :  <Button variant="contained" size="medium" color="primary" {...submitAttrs}>
                {submitLabel}
            </Button>}
        </div>
    )
}

export default Actions
