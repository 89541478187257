import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(2, 0)
    },
    circle: {
        margin: '0 auto'
    }
}))

function CircularProgressComp() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.circle} />
        </div>
    )
}

export default CircularProgressComp
