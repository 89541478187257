function getLevel(num, chartLevels, maxValue, returnObject){
    let level = returnObject ? null : 0
    for (const lvl of chartLevels) {
        if(num >= lvl.from && num < lvl.to) {
            level = returnObject ? lvl : lvl.level
            break
        }
    }
    if(maxValue && num >= maxValue){
        level = returnObject ? chartLevels[(chartLevels.length - 1)] : chartLevels[(chartLevels.length - 1)].level
    }
    return level
}

export default getLevel