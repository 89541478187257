import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from './Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from './Typography';
import { makeStyles } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { useBeforeunload } from 'react-beforeunload';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down("xs")]: {
            alignItems: 'flex-end',
            marginBottom: theme.spacing(2)
        },
        '&.over-all': {
            zIndex: '99999 !important',
            '& .MuiBackdrop-root': {
                backgroundColor: '#7a7a7c'
            }
        }
    },
    container: {
        outlineStyle: 'none',
    },
    paper: {
        padding: theme.spacing(3),
        position: 'relative'
    },
    title: {
        textAlign: 'center',
        padding: `0 ${theme.spacing(5)}px`
    },
    close: {
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(2),
            right: theme.spacing(2),
        }
    },
    body: {
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(4)
        }
    }
}));

function DialogComponent({ id, children, open, title, description, actionClick, actionTo, actionLabel = 'OK', handleClose, entitySubmit, overAll, closeDisabled }) {

    const classes = useStyles();
    const [locking, setLocking] = useState(false)

    useBeforeunload((event) => {
        if (!locking && open) {
            event.preventDefault();
        }
    });

    useEffect(() => {
        setLocking(typeof handleClose !== 'undefined' || typeof entitySubmit !== 'undefined')
    }, [handleClose, entitySubmit])

    let hasAction;
    const buttonAttrs = {}
    if (typeof actionClick !== 'undefined' && actionClick) {
        hasAction = true;
        buttonAttrs.onClick = (e) => {
            actionClick(e)
        }
    } else if (typeof actionTo !== 'undefined' && actionTo) {
        hasAction = true;
        buttonAttrs.to = actionTo;
    }

    const attrs = {}
    if (typeof handleClose !== 'undefined') {
        attrs.onClose = handleClose
    }

    return (
        <Modal
            open={open}
            closeAfterTransition
            aria-labelledby={`${id}-title`}
            aria-describedby={`${id}-description`}
            className={`${classes.root} ${overAll ? 'over-all' : ''}`}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            {...attrs}
        >
            <Fade in={open}>
                <Container maxWidth="sm" className={classes.container}>
                    <Paper className={classes.paper}>
                        {typeof handleClose !== 'undefined' && (
                            <IconButton aria-label="delete" onClick={handleClose} className={classes.close} disabled={closeDisabled}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                        <div className={classes.body}>
                            <Typography variant="body2" shade="dark" className={classes.title} id={`${id}-title`}>{title}</Typography>
                            {children}
                            {typeof description !== 'undefined' && <Typography variant="caption" align="center" id={`${id}-description`}>{description}</Typography>}
                            {hasAction && <Button color="primary" variant="contained" {...buttonAttrs}>{actionLabel}</Button>}
                            {typeof entitySubmit !== 'undefined' && (entitySubmit)}
                        </div>
                    </Paper>
                </Container>
            </Fade>
        </Modal>
    )
}

export default DialogComponent
