import { createMuiTheme } from "@material-ui/core/styles"
import hexToRgbaA from './utils/hexToRgbA'

const fontWeightRegular = 400
const fontWeightMedium = 400
const fontWeightLight = 300
const fontWeightBold = 700

export const palette = {
    purple: {
        lightest: '#F8F6FE',
        lighter: '#E8E1FA',
        light: '#977dd6',
        main: '#7E5DCC',
        dark: '#58418e',
        contrastText: '#fff',
    },
    pink: {
        lighter: '#E9D6F0',
        light: '#da93e5',
        main: '#D179DF',
        dark: '#92549c',
        contrastText: '#fff',
    },
    light: {
        lightest: '#e6eaf0',
        light: '#f1f1f9',
        main: '#e3e3ef',
        dark: '#d3d3e4',
        contrastText: '#7E5DCC',
    },
    gray: {
        lightest: '#dfe4eb',
        lighter: '#c2c8d1',
        light: '#a2a8b2',
        main: '#8B939F',
        dark: '#434C53',
        contrastText: '#fff',
    },
    blue: {
        lightest: "#edf4ff",
        lighter: "#E2EDFF",
        light: "#5172b2",
        main: "#74A3FF",
        dark: "#8fb5ff",
        contrastText: "#61666f",
    },
    lightBlue: {
        light: "#e7f0ff",
        main: "#E2EDFF",
        dark: "#9ea5b2",
        contrastText: "#61666f",
    },
    aqua: {
        lightest: "#e6f8fa",
        lighter: "#dcf4f7",
        light: "#7acfdb",
        main: "#59C4D2",
        dark: "#3e8993",
        contrastText: "#fff",
    },
    green: {
        lightest: "#e4f7e4",
        lighter: "#C9EFC8",
        light: "#6db96b",
        main: "#49A847",
        dark: "#337531",
        contrastText: "#fff",
    },
    orange: {
        lightest: "#ffe6d1",
        light: "#ff9f4f",
        main: "#FF8723",
        dark: "#b25e18",
        contrastText: "#61666f",
    },
    red: {
        lightest: "#ffe0e3",
        light: "#ef7783",
        main: "#EC5564",
        dark: "#a53b46",
        contrastText: "#fff",
    },
}

palette.primary = palette.purple
palette.secondary = palette.aqua

export const palette_scale = {
    default: palette.primary,
    danger: palette.red,
    warning: palette.orange,
    neutral: palette.blue,
    good: palette.aqua,
    great: palette.green
}

const all_headings = {
    fontSize: "1.8em",
    fontWeight: fontWeightBold,
    color: palette.gray.dark,
    margin: 0,
    lineHeight: 1.5
}

const base_theme = createMuiTheme()

const theme = createMuiTheme({
    palette,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    background: "linear-gradient(45deg, rgba(241,235,240,1) 0%, rgba(248,248,248,1) 65%, rgba(237,238,249,1) 100%)",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    fontSize: "1.05rem",
                    lineHeight: 1.8,
                    color: palette.gray.main,
                    [base_theme.breakpoints.down("sm")]: {
                        fontSize: "0.92rem",
                    }
                },
            },
        },
        MuiTypography: {
            h1: all_headings,
            h2: all_headings,
            h3: all_headings,
            h4: all_headings,
            h5: all_headings,
            h6: all_headings,
            subtitle1: {
                fontWeight: fontWeightBold,
                fontSize: "1.2em",
            },
            subtitle2: {
                fontWeight: fontWeightMedium,
                fontSize: "1.4em",
                color: palette.gray.dark
            },
            body1: {
                color: palette.gray.main,
                lineHeight: 1.8,
                fontSize: "1em",
            },
            body2: {
                color: palette.gray.dark,
                fontSize: "1.4em",
                lineHeight: 1.8,
            },
            caption: {
                color: palette.gray.main,
                lineHeight: 1.6,
                fontSize: "0.93em",
                display: "block"
            },
        },
        MuiButton: {
            label: {
                fontWeight: fontWeightBold,
            },
            containedSizeSmall: {
                padding: "0.7em 1.2em",
                fontSize: "0.8em",
            },
            outlinedSizeSmall: {
                padding: "0.7em 1.2em",
                fontSize: "0.8em",
            },
            root: {
                padding: "0.8em 2em",
                fontSize: "0.9em",
                color: palette.gray.main,
            },
            text: {
                padding: "0.8em 2em",
                fontSize: "0.9em",
            },
            containedSizeLarge: {
                padding: "1em 2.4em",
                fontSize: "1.14em",
            },
            contained: {
                color: palette.primary.main,
                backgroundColor: palette.light.light,
                "&:hover": {
                    backgroundColor: palette.light.main,
                },
                "&.Mui-disabled": {
                    backgroundColor: palette.gray.lighter,
                }
            },
            outlined: {
                borderColor: palette.gray.lighter,
                padding: "0.8em 2em",
                fontSize: "0.9em",
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: base_theme.spacing(2)
            }
        },
        MuiTab: {
            root: {
                fontWeight: fontWeightBold
            }
        },
        MuiFormControl: {
            root: {
                display: 'block',
                textAlign: 'left',
                '&.small': {
                    '&.MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                    },
                    '& .MuiOutlinedInput-input': {
                        padding: "0.5em 2em 0.5em 1em",
                        fontSize: '0.86rem'
                    },
                    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
                        transform: 'translate(11px, 9px) scale(1)',
                        fontSize: '0.86rem'
                    },
                }
            }
        },
        MuiInputBase: {
            root: {
                width: '100%',
                fontSize: '1em'
            },
            input: {
                fontSize: '1em',
                lineHeight: 1.2,
                height: 'auto'
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '1em',
                color: palette.gray.main,
                '&.Mui-focused': {
                    color: palette.gray.main,
                    '&.Mui-error': {
                        color: palette.red.main
                    }
                },
            }
        },
        MuiFormControlLabel: {
            root: {
                fontSize: '1em'
            }
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: base_theme.spacing(1),
            }
        },
        MuiOutlinedInput: {
            root: {
                '&.Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: hexToRgbaA(palette.gray.light, 0.15)
                    }
                },
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: hexToRgbaA(palette.purple.light, 0.95),
                    }
                }
            },
            notchedOutline: {
                // border: 'none',
                borderColor: hexToRgbaA(palette.purple.light, 0.45),
                backgroundColor: hexToRgbaA(palette.purple.lighter, 0.25)
            },
            inputMultiline: {
                minHeight: 100
            }
        },
        MuiSelect: {
            root: {
                color: palette.gray.dark
            },
            select: {
                minWidth: '2em'
            }
        },
        MuiTableCell: {
            root: {
                fontSize: '1.25em',
                [base_theme.breakpoints.down("sm")]: {
                    fontSize: '1em',
                }
            }
        },
        MuiAvatar: {
            root: {
                backgroundColor: palette.gray.lighter
            }
        },
        MuiDialog: {
            root: {
                '& .MuiDialog-paperWidthXs': {
                    maxWidth: 484
                }
            }
        },
        MuiGrid: {
            item: {
                width: '100%'
            }
        },
        MuiContainer: {
            maxWidthMd: {
                [base_theme.breakpoints.down("md")]: {
                    maxWidth: 720
                }
            }
        }
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeightRegular,
        fontWeightMedium,
        fontWeightLight,
        fontWeightBold
    }
})


export default theme
