import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Link, navigate } from 'gatsby'
import theme, { palette } from '../../theme'


const Custom = withStyles({
    root: {
        display: 'inline-block',
        minWidth: 0,
        padding: 0,
        maxWidth: 600,
        textAlign: 'left',
        '& .MuiSvgIcon-root': {
            fontSize: '1.5em',
            lineHeight: `${ theme.spacing(4.5) }px`,
            height: theme.spacing(4.5),
            textAlign: 'center',
            color: '#fff',
            marginLeft: 6
        },
        '& i': {
            width: theme.spacing(4.5),
            height: theme.spacing(4.5),
            lineHeight: `${ theme.spacing(4.5) }px`,
            display: 'inline-block',
            marginRight: 10,
            verticalAlign: 'middle',
            textAlign: 'center',
            backgroundColor: palette.primary.main,
            borderRadius: '50%'
        },
        '& b': {
            height: theme.spacing(4.5),
            lineHeight: `${ theme.spacing(4.5) }px`,
            display: 'inline-block',
            verticalAlign: 'middle',
            fontSize: '1.3em',
        },
        '&:hover, &:focus, &:active': {
            background: 'transparent',
            '& .MuiButton-label': {
                color: palette.primary.dark
            },
            '& i': {
                backgroundColor: palette.primary.dark
            }
        }
    },
    label: {
        fontSize: "1em",
        textTransform: 'none',
        color: palette.primary.main
    },

})(Button)

function BackButton({ children, onClick, to }) {


    const is_link = typeof to !== 'undefined'
    const comp = is_link ? Link : 'button'

    const attrs = {}

    const onClickFn = (e) => {
        if (onClick) onClick(e)
    }

    if (is_link) {
        attrs.to = to
    } else if (typeof onClick !== 'undefined') {
        attrs.onClick = onClick
    }else{
        attrs.onClick = (e) => {
            navigate(-1)
        }
    }

    return (
        <div>
            <Custom component={comp} to={to} disableRipple {...attrs}>
                <i><ArrowBackIosIcon /></i>
                <b>{children}</b>
            </Custom>
        </div>
    )
}

export default BackButton
