import {DateTime} from 'luxon'

function getTodaysCheckIn(userData) {
    if(!userData) return []
    const now = DateTime.now().toLocaleString(DateTime.DATE_SHORT);
    return Object.values(userData).find((e) => {
        if (e.attributes && e.attributes.field_checkin_date_iso) {
          const entity_date_obj = DateTime.fromISO(e.attributes.field_checkin_date_iso)
          if (now === entity_date_obj.toLocaleString(DateTime.DATE_SHORT)) {
            return true;
          }
        }
        return false
    })

}

export default getTodaysCheckIn