import mergeDeep from './mergeDeep'
import { fetchAuthenticatedContent } from '@parallelpublicworks/entitysync'

function fetchNodes(nodeType, user, userContext) {
  const jsonapiEndpoint = `node/${nodeType}?filter[uid.id]=${user}`
  const method = 'GET'
  return fetchAuthenticatedContent(userContext[0].auth, userContext[1], jsonapiEndpoint, method)
    .then(resp => {
      if (resp.data && resp.data.length) {
        let ret = { userData: {} }
        for (let goal of resp.data) {
          ret.userData[goal.id] = goal
        }
        const mergedData = mergeDeep(userContext[0], ret)
        userContext[1]({ type: 'updateUserData', payload: mergedData })
      }
      console.log('fetched', nodeType, resp);
      return resp      
    })
}

export default fetchNodes
