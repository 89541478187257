import React from 'react'
import { Section } from '.'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import getColorFromLevel from '../../utils/getColorFromLevel'
import slugify from '../../utils/slugify'

function ReportTable({ data = null }) {
    if (!data) return <></>
    return (
        <Section>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {data.header.map((th) => <TableCell key={slugify(th)}>{th}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.rows.map((row) => (
                            <TableRow key={slugify(row.label)}>
                                <TableCell>{row.label}</TableCell>
                                {Object.keys(row.cells).map((column_key) => {
                                    const level = typeof row.cells[column_key].level === 'number' ? row.cells[column_key].level : null
                                    const color = getColorFromLevel(level)
                                    return (
                                        <TableCell key={slugify(`${ row.label }-${ row.cells[column_key].value }`)} style={{ color }} >
                                            {row.cells[column_key].value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Section>
    )
}

export default ReportTable
