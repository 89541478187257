import CACTLevels from "./CACTLevels"

function getCACTLevelLabel(level) {
    let label = ''
    for (const lvl of CACTLevels) {
        if(lvl.level === level){
            label = lvl.label
            break
        }
    }
    return label
}
export default getCACTLevelLabel