import React, {useState, useEffect, useRef} from 'react'
import { makeStyles } from "@material-ui/core"
import {Icon, Typography} from './'
import theme from '../../theme'

const slot_size = 80
const rocket_size = 60

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        width: slot_size * 1.2,
        padding: `${rocket_size / 2}px 0`
    },
    bar: {
        position: 'relative',
        '& > b': {
            height: '100%',
            width: 4,
            backgroundColor: theme.palette.purple.lighter,
            display: 'block',
            position: 'absolute',
            top: 10,
            left: (slot_size / 1.75)
        }
    },
    markers: {
        textAlign: 'center'
    },
    mark: {
        height: slot_size,
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        '& span': {
            fontSize: 16,
        },
        '& i': {
            borderRadius: '50%',
            width: 12,
            height: 12,
            backgroundColor: theme.palette.purple.lighter,
            display: 'block',
            marginTop: 9,
            marginLeft: 25
        }
    },
    rocket: {
        position: 'absolute',
        left: rocket_size * 0.3,
        transform: `translateY(${rocket_size / 2}px)`,
        bottom: 0,
        transition: 'bottom 1s linear',
        width: rocket_size,
        height: rocket_size,
        zIndex: 2,
        '& i': {
            fontSize: rocket_size
        }
    },
    prize: {
        position: 'absolute',
        top: 0,
        left: '100%',
        width: 100,
        textAlign: 'left',
        marginLeft: -8
    },
    bone: {
        transition: 'transform 0.3s ease-out',
    }
}))

function RocketTimer({launch = false, seconds = 5, onComplete}) {
    const classes = useStyles()

    const [value, setValue] = useState(0)
    const [done, setDone] = useState(false)
    const [scale, setScale] = useState(false)
    const intervalRef = useRef(value)

    useEffect(() => {
        let interval;
        let timeout;
        if(launch){
            interval = setInterval(() => {
                let new_val = intervalRef.current + 1
                intervalRef.current = new_val
                setValue(new_val)
                if(new_val >= seconds){
                    timeout = setTimeout(() => {
                        setDone(true)
                        setScale(true)
                        if(onComplete){
                            onComplete()
                        }
                    }, 1000);
                    clearInterval(interval)
                }
            }, 1000);
            intervalRef.current = 1
            setValue(1)
        }else{
            setValue(0)
            setDone(false)
        }
        return ()=>{
            clearInterval(interval)
            clearTimeout(timeout)
        }
    }, [launch])

    useEffect(() => {
        let timeout;
        if(scale){
            timeout = setTimeout(() => {
                setScale(false)
            }, 300); 
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [scale])

    const markers = []
    for (let i = 0; i < seconds; i++) {
        markers.push(
            <div 
                key={`rocket-mark-${i}`}
                className={`${classes.mark} ${value === i ? 'active' : ''}`}
            >
                <span>{`${seconds - i}s`}</span>
                <i />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.bar} style={{height: slot_size * seconds}}>
                <div className={classes.markers}>
                    {markers}
                </div>
                <img src="/rocket-ship.svg"  className={classes.rocket} style={{bottom: `${value * (100 / seconds)}%`}} />
                <b />
                <div className={classes.prize}>
                    <Typography variant="caption">{done && <span>You earned </span>}<Icon size="sm" name="bone" className={classes.bone} style={{transform: `scale(${scale ? 1.5 : 1})`}} bg={done ? theme.palette.purple.main : theme.palette.purple.lighter} fg="#ffffff" /> x1 </Typography>
                </div>
            </div>
        </div>
    )
}

export default RocketTimer