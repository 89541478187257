function getLabelFromLevel(level = 0, labels){
    labels = labels || [
        'Very bad',
        'Bad',
        'Neutral',
        'OK',
        'Good',
    ]
    if(typeof labels[level] !== 'undefined') return labels[level];
    return ''
}
export default getLabelFromLevel;