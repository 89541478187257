import { fetchAuthenticatedContent } from "@parallelpublicworks/entitysync"
import mergeDeep from './mergeDeep'

function postEntity(userContext, nodeType, attributes){
  if(userContext && userContext[0] && userContext[1] && attributes){
    const jsonapiEndpoint = `node/${nodeType}`
    const body = { data: { type: nodeType, attributes } }
    return fetchAuthenticatedContent(userContext[0].auth, userContext[1], jsonapiEndpoint, 'POST', body)
      .then(resp => {
        console.warn(`postEntity ${nodeType} resp:`, resp);
        if (resp.data) {
          let ret = { userData: {} }
          ret.userData[resp.data.id] = resp.data
          const mergedData = mergeDeep(userContext[0], ret)
          userContext[1]({ type: 'updateUserData', payload: mergedData })
        }else{
          console.error('failed post', nodeType);
        }
        return resp      
      })
  }else{
    console.error('could not post', nodeType);
    return false
  }
}
export default postEntity