import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { Section, Typography } from '.'
import { makeStyles } from '@material-ui/core'
import { palette_scale } from '../../theme'
import getColorFromLevel from '../../utils/getColorFromLevel'
import slugify from '../../utils/slugify'
import { IconButton } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { ImpactContext } from '../Layout'

const useStyles = makeStyles((theme) => ({
    grades: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'left'
        },

        '& > div ': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            '& + div': {
                paddingLeft: theme.spacing(2),
                [theme.breakpoints.up("sm")]: {
                    paddingLeft: theme.spacing(4),
                },
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: 0
                },
            },
            '& .MuiTypography-caption': {
                marginRight: theme.spacing(1),
                fontWeight: theme.typography.fontWeightBold,
            },
            '& .MuiTypography-h1': {
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: '2.3em'
            },
        },
        '&.count-many': {
            [theme.breakpoints.down("xs")]: {
                display: 'block'
            },
        },
        '&.has-goto': {
            paddingRight: theme.spacing(8),
            position: 'relative'
        }
    },
    goto: {
        position: 'absolute',
        top: 0,
        right: 0
    }
}))


function ReportCard({ title, grades, to, uuid }) {

    const classes = useStyles()

    const { userData } = useContext(ImpactContext)

    const goToDetail = () => {
        if(userData && typeof uuid !== 'undefined' && typeof userData[uuid] !== 'undefined' && typeof userData[uuid].attributes !== 'undefined'){
            navigate(to, { state: { response:  userData[uuid] } });
        }else{
            navigate(to)
        }
    }

    return (
        <Section>
            <Typography variant="body1" align="left">{title}</Typography>
            <div className={`${ classes.grades } count-${ grades.length <= 2 ? 'few' : 'many' } ${typeof to !== 'undefined' ? 'has-goto' : ''}`}>
                {grades.map((grade) => {
                    const color = getColorFromLevel(grade.level || 0)
                    return (
                        <div className={classes.grade} key={`key-${ slugify(grade.label) }-${ title }`}>
                            <Typography variant="caption" shade="dark">{grade.label}</Typography>
                            <Typography variant="h1" color={color} >{grade.value}</Typography>
                        </div>
                    )
                })}
                {typeof to !== 'undefined' && (
                    <IconButton color="primary" className={classes.goto} onClick={(e) => goToDetail()}><ChevronRightIcon /></IconButton>
                )}
            </div>
        </Section>
    )
}

export default ReportCard
