import React, {useState} from 'react'
import {navigate} from 'gatsby'
import { makeStyles, IconButton, Container } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Button} from './'
import theme from '../../theme'
import patchUser from '../../utils/patchUser'

const slide_pos = {}
for (let index = 0; index < 10; index++) {
    slide_pos[`&.pos-${index}`] = {
        [`& > div > *:nth-child(${index + 1})`] : {
            opacity: 1
        }
    }
}

const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      height: '100%',
      marginTop: '1%',
      position: 'relative'
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& .MuiButtonBase-root': {
          minWidth: 0,
          '&.MuiButton-text': {
            // [theme.breakpoints.down("xs")]: {
    
            //   paddingLeft: 0,
            //   paddingRight: 0
            // }
          }
        }
    },
    dotNav: {
        color: theme.palette.primary.light,
        padding: 5,
        opacity: 0.5,
        '&.active, &:hover, &:focus, &:active': {
          opacity: 1
        },
        '& .MuiSvgIcon-root': {
          width: 12,
          height: 12
        }
    },
    slides: {
        maxWidth: 431,
        '& > div': {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            minHeight: 397,
            '& > *': {
                opacity: 0,
                fontSize: '0.84em',
                transition: 'opacity 0.4s ease-out',
                '&:not(:first-child)': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                },
            }
        },
        '& .MuiPaper-root': {
            '& .MuiTypography-root': {
                padding: theme.spacing(2, 2),
                [theme.breakpoints.down("xs")]: {
                    padding: theme.spacing(2.5),
                    paddingTop: '0.61em',
                    paddingBottom: '0.61em',
                }
            }
        },
        ...slide_pos
    }
}))


function Slideshow({children, to = '/', user, userContext, today, setOnboardingSeen, setShowDemo}) {
    
    const [loading, setLoading] = useState(false)

    const classes = useStyles()
    const [currentPosition, setCurrentPosition] = useState(0)
    const dots = []
    

    async function onSkip(e){
      const start_date = today.plus({minutes: -1})
      setLoading(true)
      setOnboardingSeen(true)
      await patchUser(user?.id, userContext, {field_onboarding_seen: true, field_start_date_iso: start_date.toISO()})
      setShowDemo(true)
      navigate(to)
    }
    
    function next(){
        let nextPos = currentPosition + 1;
        if(nextPos > 3){
          nextPos = 0
        }
        setCurrentPosition(nextPos)
    }
    
    for (let index = 0; index < children.length; index++) {
      dots.push(<IconButton disabled={loading} key={`demo-dot-${index}`} className={`${classes.dotNav} ${currentPosition === index ? 'active' : ''}`} onClick={(e) => setCurrentPosition(index)}><FiberManualRecordIcon /></IconButton>)
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" className={`${classes.slides} pos-${currentPosition}`}>
                <div>
                    {children}
                </div>
            </Container>
            <Container maxWidth="sm" className={classes.nav}>
                
                <Button disabled={loading} onClick={onSkip} inline>SKIP</Button>
                <div>
                    {dots}
                </div>
                {currentPosition < 3 && <Button disabled={loading} onClick={(e) => next()} inline variant="contained" >NEXT</Button>}
                {currentPosition === 3 && <Button disabled={loading} onClick={onSkip} inline variant="contained">DONE</Button>}
            </Container> 
        </div>
    )
}

export default Slideshow
