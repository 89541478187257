import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { IconButton, Collapse } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import { CardCollapse, Button, Typography, Modal, Actions, Alert, Icon } from '.'
import slugify from '../../utils/slugify'
import hexToRgbA from '../../utils/hexToRgbA'
import sortTimedData from '../../utils/sortTimedData'
import theme from '../../theme'
import { DateTimePicker } from "@material-ui/pickers"
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            listStyle: 'none',
            margin: 0,
            padding: 0,
            position: 'relative',
            marginBottom: theme.spacing(3),
            overflow: 'hidden',
            '&::before': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                width: 3,
                height: '999em',
                backgroundColor: theme.palette.primary.lighter,
                top: 34,
                left: 9
            },
        },
        item: {
            paddingLeft: theme.spacing(4),
            marginBottom: theme.spacing(1),
            position: 'relative',
            '&::before': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                width: 21,
                height: 21,
                border: `${ theme.palette.primary.lighter } 3px solid`,
                backgroundColor: '#fff',
                borderRadius: '50%',
                top: theme.spacing(2),
                left: theme.spacing(0)
            },
            '&.active': {
                '&::before': {
                    backgroundColor: theme.palette.primary.light,
                    borderColor: theme.palette.primary.light,
                }
            },
            '&:last-child': {
                '&::after': {
                    content: '" "',
                    display: 'block',
                    position: 'absolute',
                    width: 3,
                    height: '999em',
                    backgroundColor: 'white',
                    top: 37,
                    left: 9
                },
            }
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',

        },
        button: {
            visibility: 'hidden',
            '&.active': {
                visibility: 'visible'
            }
        },
        time: {
            padding: `0 ${ theme.spacing(1.5) }px`,
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            width: '100%',
            backgroundColor: hexToRgbA(theme.palette.primary.lighter, 0),
            borderRadius: 5,
            '& .MuiTypography-root': {
                fontSize: '1.6em',
                textAlign: 'left',
                [theme.breakpoints.down("sm")]: {
                    fontSize: '1.3em',
                }
            },
            '&:focus, &:hover, &:active': {
                backgroundColor: hexToRgbA(theme.palette.primary.lighter, 0.45),
            },
            '&:focus, &:hover, &:active, &.active': {
                outlineStyle: 'none',
                '& .MuiTypography-root': {
                    color: theme.palette.primary.main,
                    fontWeight: theme.typography.fontWeightBold
                },
            }
        },
        actions: {
            display: 'flex',
            alignItems: 'center'
        },
        notes: {
            marginTop: '0 !important',
            paddingLeft: theme.spacing(1.5),
            '& .MuiCollapse-wrapperInner': {
                '& > *:not(:last-child)': {
                    marginBottom: 0,
                },
                '& > *:last-child': {
                    marginBottom: theme.spacing(2),
                },
                '& > *:first-child': {
                    marginTop: theme.spacing(1),
                }
            }
        }
    })
)


function ReportTimeline({ data = null, edit, onDelete, onAdd, onEdit, disabled }) {
    const classes = useStyles()
    const title = "Symptoms"
    const titleSingular = "Symptom"
    const editLabel = "Add a symptom"
    const itemTitle = "Symptom"
    let today = data && data.length > 0 ? DateTime.fromISO(sortTimedData(data)[data.length - 1].time) : null
    const now = DateTime.now()
    if (today) {
        today = today.set({ hour: now.hour, minute: now.minute })
    }
    const day = today ? today : now
    const [expanded, setExpanded] = useState([])
    const [timelineData, setTimelineData] = useState(sortTimedData(data))
    const [modalOpen, setModalOpen] = useState(false)
    const [item, setItem] = useState({})
    const [modalTitle, setModalTitle] = useState(null)
    const [editing, setEditing] = useState(true)
    const [error, setError] = useState(null)
    const [confirmation, setConfirm] = useState(false)

    useEffect(() => {
        if (!modalOpen) {
            setError(null)
        }
    }, [modalOpen])

    // if (!data) return <></>

    const toggle = (key) => {
        setExpanded((prev) => {
            let next = []
            if (prev.includes(key)) {
                next = prev.filter((item) => item !== key)
            } else {
                next = [...prev, key]
            }
            return next
        })
    }


    const remove = (e, iso) => {
        if (typeof window !== 'undefined') {
            const new_data = timelineData.filter((item) => item.time !== iso)
            setTimelineData(sortTimedData(new_data))
            if (typeof onDelete !== 'undefined') {
                onDelete(e, iso, new_data)
            }
            setConfirm(false)
        }
    }
    const preconfirm = (e, iso) => {
        let current = confirmation

        return setConfirm(!current)
    }

    const add = (e) => {
        const exists = timelineData.find((itm) => itm.time === item.time)
        if (exists) {
            setError('A symptom at that time already exists')
            return false
        }
        const new_data = [...timelineData, item]
        setTimelineData(sortTimedData(new_data))
        if (typeof onAdd !== 'undefined') {
            onAdd(e, item, new_data)
        }
        return true
    }

    const editItem = (e) => {
        const new_data = timelineData.map((itm) => {
            return itm.time === item.time ? item : itm
        })
        setTimelineData(sortTimedData(new_data))
        if (typeof onEdit !== 'undefined') {
            onEdit(e, item, new_data)
        }
        return true
    }

    return (
        <>
            <CardCollapse number={timelineData.length} title={title} titleSingular={titleSingular} defaultChecked={true} lock>
                {timelineData.length > 0 && (
                    <ul className={classes.list}>
                        {timelineData.map((itm) => {
                            const dt = DateTime.fromISO(itm.time)
                            const key = itm.time
                            const is_active = expanded.includes(key)
                            return (
                                <li key={key} className={`${ classes.item } ${ is_active ? 'active' : '' }`} >
                                    <div className={`${ classes.heading } ${ is_active ? 'active' : '' }`}>
                                        <button onClick={(e) => toggle(key)} className={`${ classes.time } ${ is_active ? 'active' : '' }`}>
                                            <Typography variant="body2">{dt.toLocaleString(DateTime.TIME_SIMPLE)}</Typography>
                                        </button>
                                        {!confirmation ?  edit && ( 
                                            <div className={classes.actions}>
                                                <IconButton onClick={(e) => preconfirm(e, key)} className={`${ classes.button } ${ is_active ? 'active' : '' }`}><DeleteIcon color="primary" /></IconButton>
                                                <IconButton onClick={(e) => { setItem(itm); setModalTitle(`Edit ${ itemTitle }`); setEditing(true); setModalOpen(true) }} className={`${ classes.button } ${ is_active ? 'active' : '' }`}><EditIcon color="primary" /></IconButton>
                                            </div>
                                        )
                                        :
                                        edit && ( 
                                            <div className={classes.actions}>
                                                <IconButton onClick={(e) => preconfirm(e, key)} className={`${ classes.button } ${ is_active ? 'active' : '' }`}><CloseIcon color="primary" /></IconButton>
                                                <IconButton onClick={(e) => remove(e, key)} className={`${ classes.button } ${ is_active ? 'active' : '' }`}><CheckIcon color="primary" /></IconButton>
                                            </div>
                                        )
                                        }

                                    </div>
                                    <Collapse in={is_active} className={`${ classes.notes } ${ is_active ? 'active' : '' }`}>
                                        {typeof itm.location !== 'undefined' && <Typography variant="body1">Location: {itm.location}</Typography>}
                                        {typeof itm.trigger !== 'undefined' && <Typography variant="body1">Trigger: {itm.trigger}</Typography>}
                                    </Collapse>
                                </li>
                            )
                        })}
                    </ul>
                )}
                {edit && (
                    <Button variant="outlined" color="primary" disabled={disabled} onClick={(e) => {
                        setItem({
                            time: today ? today.toISO() : now.toISO()
                        })
                        setModalTitle(`Add ${ itemTitle }`)
                        setEditing(false)
                        setModalOpen(true)
                    }}>
                        {editLabel}
                    </Button>
                )}
            </CardCollapse>
            <Modal
                id="add-modal"
                open={modalOpen}
                size="sm"
                title={modalTitle}
                handleClose={(e) => setModalOpen(false)}
                footer={<Button variant="contained" color="primary" onClick={(e) => {
                    let success
                    if (editing) {
                        success = editItem(e)
                    } else {
                        success = add(e)
                    }
                    if (success) {
                        setModalOpen(false)
                    }
                }}>Submit</Button>}
            >
                {error && (
                    <Alert
                        icon={<Icon name="cancel" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="sm" />}
                        color="red"
                        size="sm"
                    >
                        <span>{error}</span>
                    </Alert>
                )}
                <DateTimePicker
                    minutesStep={5}
                    disabled={editing}
                    inputVariant="outlined"
                    ampm={true}
                    value={typeof item.time !== 'undefined' ? DateTime.fromISO(item.time) : today ? today : now}
                    label="Time:"
                    maxDate={day}
                    minDate={day}
                    openTo="hours"
                    onChange={(e) => {
                        setItem({ ...item, time: e.toISO() })
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Location:"
                    onChange={(e) => {
                        setItem({ ...item, location: e.target.value })
                    }}
                    value={typeof item.location !== 'undefined' ? item.location : ''}
                />
                <TextField
                    variant="outlined"
                    label="Trigger:"
                    onChange={(e) => {
                        setItem({ ...item, trigger: e.target.value })
                    }}
                    value={typeof item.trigger !== 'undefined' ? item.trigger : ''}
                />
            </Modal>
        </>
    )
}

export default ReportTimeline
