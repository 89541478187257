import {DateTime} from 'luxon'

function getWeekCheckIns(userData, interval){
    if(!userData || !interval) return []
    const filterCheckIns = (uuid) => {
        const dateInRange = (iso) => {
            const lDate = DateTime.fromISO(iso)
            return interval.contains(lDate)
        }
        if(
            typeof userData[uuid] !== 'undefined' && 
            typeof userData[uuid].type !== 'undefined' &&
            typeof userData[uuid].attributes !== 'undefined' &&
            userData[uuid].type === 'node--asthma_check_in' &&
            typeof userData[uuid].attributes.field_checkin_date_iso === 'string'
        ){
            return dateInRange(userData[uuid].attributes.field_checkin_date_iso)
        }else {
            return false
        }
    }
    return Object.keys(userData).filter(filterCheckIns)
}
export default getWeekCheckIns