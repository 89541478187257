import React from 'react'
import Image from 'material-ui-image'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& > div': {
            background: 'transparent !important'
        },
        '&.padded': {
            padding: theme.spacing(6),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(4),
            },
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(2),
            },
        }
    }
}))

function ImageComponent({ src, shadow, width = '100%', padding, aspectRatio = 1, background = 'transparent', borderRadius = 0, onLoad, className = '', disableTransition }) {
    const classes = useStyles()
    const boxShadow = typeof shadow !== 'undefined' ? '0px 0px 10px rgb(0 0 0 / 10%)' : 'none'
    return (
        <div className={`${ classes.root } ${ padding && 'padded' } ${ className }`} style={{ width, background, borderRadius, boxShadow }}>
            <Image onLoad={(e) => {
                if (typeof onLoad !== 'undefined') onLoad(e)
            }} src={src} aspectRatio={aspectRatio} disableTransition={disableTransition} />
        </div>
    )
}

export default ImageComponent
