import React, { useState, useContext } from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import { Icon, Typography, Card, Modal } from '.'
import getColorFromLevel from '../../utils/getColorFromLevel'
import theme from '../../theme'
import { ImpactContext } from '../Layout'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2.5),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1.3),
        },
        '& > div': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: -(theme.spacing(1)),
        [theme.breakpoints.down("sm")]: {
            marginBottom: -(theme.spacing(0.25)),
        }
    },
    body: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        '& + .info': {
            paddingLeft: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(2),
            }
        }
    },
    info: {
        textAlign: 'left',
        '& .stats': {
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                paddingRight: theme.spacing(1)
            },
            '& .MuiTypography-h1': {
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: '2.2em',
                [theme.breakpoints.down("xs")]: {
                    fontSize: '1.9em',
                }
            }
        }
    },
    link: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            '& > div': {
                backgroundColor: `${ theme.palette.secondary.lighter } !important`,
                transform: 'scale(1.2)',
                '& i': {
                    color: `${ theme.palette.secondary.main } !important`,
                }
            }
        }
    },
    indicator: {
        '&.direction-up': {
            transform: 'rotate(-180deg)'
        }
    }
}))

function ReportGlance({ 
    title, 
    help, 
    helpModal = null,
    icon, 
    icon_color = 'green', 
    number, 
    description, 
    level = 0, 
    note, 
    to,
    toUUID,
    onClick, 
    shadowOff, 
    cardBg = 'white', 
    indicator, 
    indicatorDirection, 
    actionIconBg, 
    actionIconFg,
    disableLevelColors,
}) {

    const {userData} = useContext(ImpactContext)

    const [openHelpModal, setOpenHelpModal] = useState(false)

    const classes = useStyles()
    let icon_colors = {
        fg: theme.palette[icon_color].light,
        bg: theme.palette[icon_color].lighter,
    }
    let title_label = null
    if (title && help) {
        title_label = (
            <>
                <Typography variant="body1" component="div" color={theme.palette.gray.dark} className={classes.title}>
                    {title}
                    {helpModal ? (
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setOpenHelpModal(true)
                        }}><Icon name="help" bg="transparent" fg={theme.palette.gray.dark} /></a>
                    ) : (
                        <Tooltip title={help} aria-label="help" placement="top">
                            <a><Icon name="help" bg="transparent" fg={theme.palette.gray.dark} /></a>
                        </Tooltip>
                    )}
                </Typography>
            </>
        )
    } else if (title) {
        title_label = <Typography variant="body1" className={classes.title} color={theme.palette.gray.dark}>{title}</Typography>
    }

    const go = (e) => {
        if(toUUID && typeof userData[toUUID] !== 'undefined'){
            e.preventDefault()
            navigate(to, { state: { response:  userData[toUUID] } });
        }
    }

    const level_color = disableLevelColors ? theme.palette.primary.main : number === '-' ? theme.palette.gray.main : getColorFromLevel(level)
    let action = null
    if(onClick){
        action = <button onClick={onClick} className={classes.link}><Icon name="stats" bg={actionIconBg} fg={actionIconFg} /></button>
    }else if(to){
        action = <Link to={to} className={classes.link} onClick={go} ><Icon name="angle-right" bg={actionIconBg} fg={actionIconFg} /></Link>
    }
    return (
        <>
            {helpModal && (
                <Modal 
                id="help-modal"
                open={openHelpModal}
                title={help || 'Info'}
                handleClose={(e) => setOpenHelpModal(false)}
                >
                    {helpModal}
                </Modal>
            )}
            <Card bg={cardBg} className={classes.root} shadowOff={shadowOff} >
                <div>
                    <div className={classes.body}>
                        {icon && <Icon name={icon} size="lg" {...icon_colors} className={classes.icon} />}
                        <div className={`${classes.info} info`}>
                            <>
                                {title_label}
                                <div className="stats">
                                    {typeof number === 'number' || typeof number === 'string' && <Typography variant="h1" color={level_color}>{number}</Typography>}
                                    {description && <Typography variant="body2">{description}</Typography>}
                                    {typeof indicator === 'number' || typeof indicator === 'string'  && <Typography variant="caption">{indicatorDirection && <Icon name={indicatorDirection === 'none' ? 'none' : 'down'} className={`${classes.indicator} direction-${indicatorDirection}`} bg="transparent" fg={theme.palette.gray.dark} />} {(typeof indicator === 'number' && indicator > 0) || typeof indicator === 'string' ? indicator : ''}</Typography>}
                                </div>
                                {note && <Typography variant="caption">{note}</Typography>}
                            </>
                        </div>
                    </div>
                    {action}
                </div>
            </Card>
        </>
    )
}

export default ReportGlance
