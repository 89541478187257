import React, {useState} from 'react';
import { UserContext, handleLogin } from "@parallelpublicworks/entitysync";
import { useContext, useEffect } from "react";

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { Logo, Typography, Button, Alert, Icon } from '../components/library'
import hexToRgbA from '../utils/hexToRgbA'

function Copyright() {
  return (
    <Typography variant="caption" color="gray" align="center">Copyright © IMPACT Wearable {new Date().getFullYear()}</Typography>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as 'column'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login({ location }) {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  const [noJs, setNoJs] = useState(true)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNoJs(false)
  }, [])

  useEffect(() => {
    if(noJs === false && typeof window !== 'undefined'){
      setNoJs(null)
      localStorage.clear()
    }
  }, [noJs])

  useEffect(() => {
    let timeout;
    if(loading){
      timeout = setTimeout(() => {
        setLoading(false)
        setError('An error occurred')
      }, 6000); 
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [loading])

  return (
    <>
      <Container className={classes.paper} maxWidth="sm">
        <Logo />
        <br />
        <Typography variant="h1" shade="dark">
          Sign in
        </Typography>
        {error && (
          <>
            <br />
            <Alert
                icon={<Icon name="cancel" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="sm" />}
                color="red"
                size="sm"
            >
              <span>{error}</span>
            </Alert>
          </>
        )}
        <form className={classes.form} noValidate onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          setError(null);
          handleLogin(userContext, e);
        }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="Username"
            autoFocus
            disabled={loading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || noJs}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              {/* <Link href="#">
                <Typography variant="caption" color="primary">Forgot password?</Typography>
              </Link> */}
            </Grid>
            <Grid item>
            <Link href='https://uwimpactstudy.com/screening' target='_blank'>
                <Typography variant="caption" color="primary">Want to learn more?</Typography>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box mt={4}>
        <Copyright />
      </Box>
    </>
  );
}
function LoginPage({ location }) {
  return (

    <Login location={location} />

  );
}
export default LoginPage;
