function mergeDeep(target, source){
  const ret = {
    ...target,
    ...source
  };

  for(let targetKey of Object.keys(target)){
    if(source.hasOwnProperty(targetKey)
      && typeof source[targetKey] === 'object'
      && typeof target[targetKey] === 'object'
      && source[targetKey] !== null
      && target[targetKey] !== null
      && (!Array.isArray(target[targetKey])
      || !Array.isArray(source[targetKey]))
    ){
      ret[targetKey] = mergeDeep(target[targetKey], source[targetKey]);
    }
    else if(source.hasOwnProperty(targetKey)){
      ret[targetKey] = source[targetKey];
    }
    else{
      ret[targetKey] = target[targetKey];
    }
  }

  return ret;
}

export default mergeDeep
