import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import elasticlunr from "elasticlunr"
import { DateTime } from 'luxon'
import { fade, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { IconButton } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TableRow from '@material-ui/core/TableRow'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import { Section, Typography } from '.'
import getColorFromLevel from '../../utils/getColorFromLevel'
import slugify from '../../utils/slugify'
import getMoodOptions from '../../utils/getMoodOptions'
import sortTimedData from '../../utils/sortTimedData'
import getCACTLevelLabel from '../../utils/getCACTLevelLabel'

const useStyles = makeStyles((theme) => ({
    icon: {
        '& i': {
            fontSize: 40,
            lineHeight: 1,
            width: 40,
            display: 'block',
            '&:before': {
                margin: 0
            }
        },
        '& span': {
            display: 'none'
        }
    },
    cell: {
        padding: theme.spacing(1, 1, 1, 1),
        '&.last': {
            width: 60
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: 0,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        '& .MuiInputBase-input': {
            paddingLeft: `calc(1em + ${ theme.spacing(4) }px)`,
        }
    },
    levelContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    medPoint: {
        width: '15px',
        height: '15px', 
        borderRadius: '50%',
        marginLeft: '5px',
        marginRight: '5px',
        '&.daily': {
            background: '#37BFBF',
        },
        '&.rescue': {
            background: '#FF8723',
        }
    },

}))

function ReportArchive({ data = null, title, searchPlaceholder = 'Search by location, trigger and date...' }) {
    const classes = useStyles()
    if (!data) return <></>
    const moods = getMoodOptions()
    const [archiveData, setArchiveData] = useState(sortTimedData(data, 'DESC'))
    const [index, setIndex] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [matches, setMatches] = useState([])

    useEffect(() => {
        if (!index) {
            setIndex(elasticlunr(function () {
                this.addField('desc')
                this.setRef('time')
            }))
        }
    }, [])

    useEffect(() => {
        if (index !== null) {
            for (const itm of data) {
                const keywords = new Set()
                if (itm.symptoms) {
                    for (const symptom of itm.symptoms) {
                        if (symptom.location) keywords.add(symptom.location)
                        if (symptom.trigger) keywords.add(symptom.trigger)
                    }
                };
                if(itm.level){
                    keywords.add(getCACTLevelLabel(itm.level))
                }
                if(itm.score){
                    keywords.add(itm.score)
                }
                const doc = {
                    time: itm.time,
                    desc: `${ Array.from(keywords).join(' ') } ${ DateTime.fromISO(itm.time).toFormat('cccc d LLLL y') }`
                }
                index.addDoc(doc)
            }
        }
    }, [index])

    useEffect(() => {
        if (index) {
            if (searchQuery.length > 0) {
                setMatches(index.search(searchQuery, {
                    fields: {
                        desc: { boost: 2 },
                    },
                    expand: true,
                    bool: "AND",
                }))
            } else {
                setArchiveData(sortTimedData(data, 'DESC'))
            }
        }
    }, [searchQuery])

    useEffect(() => {
        const matching_refs = matches.map((m) => m.ref)
        const found = data.filter((itm) => matching_refs.includes(itm.time))
        if (found && found.length > 0) {
            setArchiveData(sortTimedData(found, 'DESC'))
        } else if (searchQuery.length > 0) {
            setArchiveData([])
        } else {
            setArchiveData(sortTimedData(data, 'DESC'))
        }
    }, [matches])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const dateStr = urlParams.get("date");
        
        let dateParam = DateTime.fromJSDate(new Date(dateStr))

        if(dateParam.isValid) {
            let checkinFound = archiveData.find(item => DateTime.fromISO(item.time).hasSame(dateParam, 'day'));

            if(checkinFound) {
                let asthmaItem = checkinFound.to[1];
                checkinFound.to[1] = {...asthmaItem, fromIndex: true }
                goToDetail(null, checkinFound.to)
            }
        }

    }, [window.location])

    const searchFn = (e) => {
        setSearchQuery(e.target.value)
    }

    const goToDetail = (e, to) => {
        if(typeof to === 'string'){
            navigate(to)
        }else if(Array.isArray(to) && to.length === 2){
            navigate(to[0], { state: { response:  to[1] } });
        }
    }

    const dateTimeToLabel = (dt) => {
        const two_days_milli = 1000 * 60 * 60 * 24 * 2
        const diff = DateTime.now() - dt
        return diff > two_days_milli ? <Typography inline variant="caption">{dt.toLocaleString(DateTime.DATETIME_MED)}</Typography> : <Typography inline variant="caption">{`${ dt.toRelativeCalendar() }, ${ dt.toLocaleString(DateTime.TIME_SIMPLE) }`}</Typography>
    }



    return (
        <Section title={title}>
            <>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <TextField
                        placeholder={searchPlaceholder}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'search' }}
                        className={classes.inputInput}
                        onChange={searchFn}
                        value={searchQuery}
                    />
                </div>
                {archiveData.length === 0 && (
                    <Typography variant="caption">
                        No results
                    </Typography>
                )}
                {archiveData.length > 0 && (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {archiveData.map((row) => {
                                    const level = row.level ? row.level : 0
                                    const color = getColorFromLevel(level)
                                    const mood = moods.find((m) => m.value === level)
                                    return (
                                        <TableRow key={row.time}>
                                            {Object.keys(row).map((column_key, index) => {
                                                let val
                                                let is_last = index === Object.keys(row).length - 1
                                                if (typeof row[column_key] === 'undefined') {
                                                    val = '-'
                                                } else {
                                                    switch (column_key) {
                                                        case 'time':
                                                            const dt = DateTime.fromISO(row[column_key])
                                                            val = dateTimeToLabel(dt)
                                                            break
                                                        case 'date':
                                                            val = dateTimeToLabel(row[column_key])
                                                            break
                                                        case 'symptoms':
                                                            const s_count = Array.isArray(row[column_key]) ? row[column_key].length : 0
                                                            val = <span><Typography inline color="primary">{s_count}</Typography> <Typography inline>{s_count === 1 ? 'symptom' : 'symptoms'}</Typography></span>
                                                            break
                                                        case 'score':
                                                            val = <span><Typography inline color={color}>{row[column_key]}</Typography> <Typography inline>{getCACTLevelLabel(row.level)}</Typography></span>
                                                            break
                                                        case 'level':
                                                            val = (
                                                                <div className={classes.levelContainer}>
                                                                    <Typography color={color} className={classes.icon}>{mood.label}</Typography>
                                                                    {row['hasTakenDailyMed'] && <div className={classes.medPoint + ' daily'}></div>}
                                                                    {row['hasTakenRescueMed'] && <div className={classes.medPoint + ' rescue'}></div>}
                                                                </div>
                                                            )
                                                            break
                                                        case 'to':
                                                            val = <IconButton color="primary" onClick={(e) => goToDetail(e, row[column_key])}><ChevronRightIcon /></IconButton>
                                                            break
                                                        case 'hasTakenDailyMed':
                                                            val = null
                                                            break
                                                        case 'hasTakenRescueMed':
                                                            val = null
                                                            break
                                                        default:
                                                            val = row[column_key]
                                                            break
                                                    }
                                                }
                                                return val ? (
                                                    <TableCell key={slugify(`${ row.time }-${ row[column_key] }`)} className={`${ classes.cell } ${ is_last ? 'last' : '' }`} >
                                                        {val}
                                                    </TableCell>
                                                ) : null
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </>
            
        </Section>
    )
}

export default ReportArchive
